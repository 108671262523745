import React, { Component } from 'react';
import { Input, Button, ButtonGroup } from 'semantic-ui-react';
import SurveyData from './SurveyData';


export default class Q3InternationalAdjusters extends Component {
  state = this.props.state;
  step = this.props.step;

  incrementValue = e => {
    const step = this.props.step;
    var SurveyStep = SurveyData[step];
    if (this.state[step][4] < this.props.max){
      this.setState(
        (prevState) => {
          var newState = prevState;
          newState[step][4]++;
          return(newState)
        }
      )
      //SurveyStep[4] = SurveyStep[4] +1;
      SurveyData[step] = SurveyStep;
    }
  }

  decrementValue = e => {
    const step = this.props.step;
    var SurveyStep = SurveyData[step];
    if (this.state[step][4] > this.props.min){
      this.setState(
        (prevState) => {
          var newState = prevState;
          newState[step][4]--;
          return(newState)
        }
      )
      //SurveyStep[4] = SurveyStep[4] - 1;
      SurveyData[step] = SurveyStep;
    }
  }  

  render () {
    return (
      <React.Fragment>
        <div className="ui grid">
          <div className="ui left floated middle aligned ten wide column">
            <p>Other International</p>
          </div>
          <div className="ui right floated middle aligned six wide column">
            <ButtonGroup basic>
              <Button icon="minus" className="button-theme" 
                labelPosition='right' 
                label={{as:'a', basic:true, content:`${this.state[this.step][4]}`, pointing:false, className:'numberlabel'}}
                onClick={this.decrementValue}/>
              <Button icon="plus"className="button-theme" onClick={this.incrementValue} />
            </ButtonGroup>
            <Input className="invisible" content={this.state[this.step][4]} />
          </div>
        </div> 
        </React.Fragment>
    )
  }
}
