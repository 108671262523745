import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCe3LApYcJfzItD9IrbXu1niTRM07DxXMA",
  authDomain: "pledge-to-lead.firebaseapp.com",
  databaseURL: "https://pledge-to-lead.firebaseio.com",
  projectId: "pledge-to-lead",
  storageBucket: "pledge-to-lead.appspot.com",
  messagingSenderId: "30723371770",
  appId: "1:30723371770:web:227060f193120fff",
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.fs = app.firestore();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // doSendEmailVerification = () =>
  //   this.auth.currentUser.sendEmailVerification({
  //     url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  //   });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((doc) => {
            const user = doc.data();
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...user,
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  // user = (uid) => this.fs.collection("users").where("userId", "==", uid);

  user = (uid) => this.fs.collection("users").doc(uid);

  updateUser = (uid) => this.fs.collection("users").doc(uid);

  // userListener = (uid) => this.fs.collection("users").doc(uid);

  users = () => this.fs.collection("users");

  deleteField = () => this.fs.FieldValue.delete()

  feedback = () => this.fs.collection("feedback");

  tempusers = () => this.fs.collection("tempusers");
  tempusers2 = () => this.fs.collection("tempusers2");
}

export default Firebase;
