import React from 'react';
import { Form, Radio } from 'semantic-ui-react';

const Q5 = props => {
  return (
    <React.Fragment>
      <h2>What best describes your home?</h2>
      <Form>
        <Form.Field>
          <Radio
            label='Detached or semi-detached'
            name='home'
            value={459.3522}
            checked={props.state[props.step] === 459.3522}
            onChange={props.handleChange}            
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Row or Terrace'
            name='home'
            value={430.2226}
            checked={props.state[props.step] === 430.2226}
            onChange={props.handleChange}            
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Apartment or Condo'
            name='home'
            value={232.8335}
            checked={props.state[props.step] === 232.8335}
            onChange={props.handleChange}            
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Mobile Home'
            name='home'
            value={374.0003}
            checked={props.state[props.step] === 374.0003}
            onChange={props.handleChange}            
          />
        </Form.Field>
      </Form>
    </React.Fragment>
  );
}
export default Q5;
