import React from 'react';
import { Form, Radio } from 'semantic-ui-react';

const Q9 = props =>{
  return (
    <React.Fragment>
      <h2>What best describes your diet?</h2>
      <Form>
        <Form.Field>
          <Radio
            label='Vegan'
            name='diet'
            value={1500}
            checked={props.state[props.step] === 1500}
            onChange={props.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Vegetarien'
            name='diet'
            value={1700}
            checked={props.state[props.step] === 1700}
            onChange={props.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Omnivore who doesn't eat red meat"
            name='diet'
            value={1900}
            checked={props.state[props.step] === 1900}
            onChange={props.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Omnivore who eats red meat'
            name='diet'
            value={3300}
            checked={props.state[props.step] === 3300}
            onChange={props.handleChange}
          />
        </Form.Field>
      </Form>
    </React.Fragment>
  );
}

export default Q9;