import React from "react";
import { Card, Image } from "semantic-ui-react";

const CommentCard = (props) => {
  const { avatar, author, content, timeAgo } = props;
  return (
    <Card raised>
      <Card.Content>
        <Card.Header textAlign="left">
          <Image avatar src={avatar} alt={author} />
          {author}
        </Card.Header>
        <Card.Description textAlign="left" content={content} />
        <Card.Meta textAlign="right" content={timeAgo} />
      </Card.Content>
    </Card>
  );
};

export default CommentCard;
