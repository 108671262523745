import React from "react";
import { Accordion, Form, Radio } from "semantic-ui-react";
import ExtQs from "../ExtendedQuestions.json";
import PropTypes from "prop-types";

const questions = ExtQs.Diet;

const Diet = (props) => {
  const { Questions, userActions, updateUser } = props;
  
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(
    userActions.filter((c) => c.id === "A6").length > 0
      ? userActions.filter((c) => c.id === "A6")[0].visible
        ? 1
        : 0
      : 1
  );
  const handleChange = (e, data) => {
    e.preventDefault();
    setLoading(true);
    const { target, value, label } = data;
    const checked = label.toLowerCase() === "no" ? false : true;
    const targetIndex = userActions.findIndex((c) => c.id === target);
    const newUserActions = userActions;
    newUserActions[targetIndex].visible = checked;
    if (checked && Questions.Q9 >= 1900) {
      updateUser()
        .update({
          userActions: newUserActions,
        })
        .then(() => {
          console.log("Document successfully updated!");
          setLoading(false);
          setValue(value);
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } else {
      updateUser()
        .update({
          userActions: newUserActions,
        })
        .then(() => {
          console.log("Document successfully updated!");
          setLoading(false);
          setValue(value);
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
      setValue(value);
    }
  };

  const panels = [
    {
      key: "Diet",
      title: "Are you interested in considering dietary changes?",
      content: {
        content: (
          <Form loading={loading}>
            {questions.DietaryChange.map((option, key) => {
              return (
                <Form.Field>
                  <Radio
                    label={option.Name}
                    value={option.EF}
                    target={option.target}
                    modifies={option.modifies}
                    checked={value === option.EF}
                    onChange={handleChange}
                    style={{ marginRight: "20px" }}
                  />
                </Form.Field>
              );
            })}
          </Form>
        ),
      },
    },
  ];
  return userActions.filter((c) => c.id === "A6").length > 0 ? (
    <Accordion defaultActiveIndex={-1} panels={panels} />
  ) : (
    <p>No additional questions at this time... Check back soon!</p>
  );
};

Diet.propTypes = {
  updateUser: PropTypes.func.isRequired,
};

export default Diet;
