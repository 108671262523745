import React from 'react';
import { Form, Checkbox} from 'semantic-ui-react';

const Q6 = props => {
  const handleChange =(e,data) => {
    data.checked?props.state[props.step] += parseFloat((data.value).toFixed(8)): props.state[props.step]-=parseFloat((data.value).toFixed(8));
  }
  return (
    <React.Fragment>
      <h2>Aside from Hydro-electricity, what sources of energy do you use?</h2>
      <Form>
        <Form.Field>
          <Checkbox 
            label='Natural gas'
            name='Natural gas'
            value={51.00298387}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox 
            label='Heating Oil'
            name='Heating Oil'
            value={71.19684755}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox 
            label='Propane'
            name='Propane'
            value={58.05924812}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox 
            label='None'
            name='None'
            value={0.0001}
            onChange={handleChange}
          />
        </Form.Field>
      </Form>
    </React.Fragment>
  );
}
export default Q6;