import React, { Component } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Sector } from 'recharts';
import { Segment } from 'semantic-ui-react'
import {isMobile } from "react-device-detect";

class PieChartRespLogin extends Component {

    state = {
        activeIndex: 0,
      };

	render () {

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
            fill, value, key } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 12;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        let innerText = () =>{
            if(isMobile) {
                return (
                    <>
                    <tspan x={ex + (cos >= 0 ? 1 : -1) * 14} dy="1.6em">{`${value.toFixed(0)} kg`}</tspan>
                    <tspan x={ex + (cos >= 0 ? 1 : -1) * 14} dy="1.2em">CO2e/yr</tspan>
                    </>
                )
            } else {
                return `(${value.toFixed(0)} kg CO2e/yr)`
            }
        };

        return (
            <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/> */}
            <text x={ex + (cos >= 0 ? 1 : -1) * 5} y={ey +5} textAnchor={textAnchor} fill="#333"  className="label">{`${key}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * -12} y={ey} dy={18} textAnchor={textAnchor}  fill="#999" className="label sub">
                {innerText()}
            </text>
            </g>
        );
    };
    // const renderCustomizedLabelMobile = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, value, key }) => {
    //     const RADIAN = Math.PI / 180;
    //     const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    //     const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    //     const y = cy  + radius * Math.sin(-midAngle * RADIAN);
      
    //     return (
    //         <g>
    //         <Sector
    //             cx={cx}
    //             cy={cy}
    //             innerRadius={innerRadius}
    //             outerRadius={outerRadius}
    //             startAngle={startAngle}
    //             endAngle={endAngle}
    //             fill={fill}
    //         />
    //         <Sector
    //             cx={cx}
    //             cy={cy}
    //             startAngle={startAngle}
    //             endAngle={endAngle}
    //             innerRadius={outerRadius + 6}
    //             outerRadius={outerRadius + 10}
    //             fill={fill}
    //         />
    //         <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" className="label">
    //           {`${key}`}
    //         </text>
    //         <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dy={18} dominantBaseline="central" className="label sub">
            
    //         {/* {`(${value.toFixed(0)} kg \n CO2e/yr)`} */}
    //         <tspan x="0" dy="1.2em">{`${value.toFixed(0)} kg`}</tspan>
    //         <tspan x="0" dy="1.2em">CO2e/yr</tspan>
    //       </text>
    //       </g>
    //    );
    //   };

    const onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    }

    if (this.props.data === null) {
        return(
            <Segment basic loading className="full-h">
            </Segment>
        )
    } else {
        return (
        <React.Fragment>
            <div className="pieOuter">
                <div className="PieChart" >
                    <ResponsiveContainer width={isMobile?'99%':'100%'} height='100%'>
                        <PieChart >
                            <Legend iconSize={10}
                                layout='horizontal'
                                verticalAlign='bottom'
                                formatter= {(key, entry) =>{
                                    // const {color} = entry;
                                    return (
                                        <>
                                        <span className="recharts-legend-item-text">{entry.key} ({((entry.value/this.props.total)*100).toFixed(0)}%)</span>
                                        </>
                                    )

                                }}
                                nameKeyString="key"
                                payload = {this.props.data}
                            />
                            <text fill="grey" x='50%' y={isMobile?"42%":"46%"} dominantBaseline="auto" textAnchor="middle" className="centroid">
                            {this.props.total} kg
                            </text>
                            <text fill="grey" x="50%" y={isMobile?"42%":"46%"} dy={isMobile?14:18} dominantBaseline="auto" textAnchor="middle"className="centroid">
                            CO2e/year
                            </text>
                            <Pie
                                activeIndex={this.state.activeIndex}
                                // activeShape={isMobile?renderCustomizedLabelMobile:renderActiveShape}
                                activeShape={renderActiveShape}
                                nameKeyString="key"
                                data= {this.props.data}
                                cx='50%'
                                cy='50%'
                                innerRadius='25%'
                                outerRadius={isMobile?"50%":"75%"}
                                fill="#0500d6"
                                paddingAngle={0}
                                labelLine={isMobile?false:true}
                                onMouseEnter={onPieEnter}
                            >
                                {this.props.data.map((entry, index) => <Cell key={`cell_${index}`}fill={entry.color}/>)}
                            </Pie>
                        </PieChart>
                </ResponsiveContainer>
            </div>
            </div>
        </React.Fragment>
        );
    }
  }
};

export default PieChartRespLogin;
