import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {
  Button,
  Segment,
  Image,
  Grid,
  Divider,
  Dropdown,
} from "semantic-ui-react";
import logo from "../../constants/images/ptl_logo_earth_wide_blue.svg";
import { isMobile } from "react-device-detect";

const Header = (props) => {
  const { doSignOut } = props.firebase;
  const { authUser } = props;

  const signOut = () => {
    doSignOut()
      .then(() => {
        props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Segment
      fluid="true"
      basic
      style={{ backgroundColor: "#12d6b2", marginTop: 0, marginBottom: 0 }}
    >
      <Grid verticalAlign="middle" columns="equal">
        <Grid.Column textAlign="left">
          <Image src={logo} size="small" alt="logo" href={ROUTES.HOME} />
        </Grid.Column>
        {!isMobile && (
          <Grid.Column textAlign="right">
            {authUser ? (
              <React.Fragment>
                <Button
                  size={isMobile ? "small" : "large"}
                  onClick={signOut}
                  content="SIGN OUT"
                  icon="sign-out"
                  style={{
                    marginRight: "1em",
                    backgroundColor: "white",
                    color: "#00005a",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    marginBottom: "1em",
                  }}
                />
                <Button
                    size="large"
                    as={Link}
                    to={ROUTES.DASHBOARD}
                    content="DASHBOARD"
                    icon="user circle"
                    style={{
                      backgroundColor: "white",
                      color: "#00005a",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                  />
                {/* {!isMobile && (
                  <Button
                    size="large"
                    as={Link}
                    to={ROUTES.QUIZ}
                    content="START AGAIN"
                    style={{
                      backgroundColor: "white",
                      color: "#00005a",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                  />
                )} */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  size={isMobile ? "small" : "large"}
                  as={Link}
                  to={ROUTES.LOG_IN}
                  content="SIGN IN"
                  icon="sign-in"
                  style={{
                    marginRight: "1em",
                    backgroundColor: "white",
                    color: "#00005a",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    marginBottom: "1em",
                  }}
                />
                  <Button
                    size="large"
                    as={Link}
                    to={ROUTES.QUIZ}
                    icon="comments"
                    content="GET STARTED"
                    style={{
                      backgroundColor: "white",
                      color: "#00005a",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                  />
              </React.Fragment>
            )}
          </Grid.Column>
        )}
        {isMobile && (
          <Grid.Column textAlign="right">
            <Dropdown text="" floating icon={{ name: "bars", size: "large" }}>
              <Dropdown.Menu direction="left">
                {authUser ? (
                  <Dropdown.Item onClick={signOut} content="Sign Out" icon="sign-out" />
                ) : (
                  <Dropdown.Item
                    as={Link}
                    to={ROUTES.LOG_IN}
                    icon="sign-in"
                    active={ROUTES.LOG_IN === props.location.pathname}
                    content="Sign In"
                  />
                )}
                {!authUser && <Dropdown.Item
                  as={Link}
                  to={ROUTES.QUIZ}
                  icon="signup"
                  active={ROUTES.QUIZ === props.location.pathname}
                  content={authUser ? "Start Again" : "Get Started"}
                />}
                {authUser && (
                  <Dropdown.Item
                    as={Link}
                    icon="user circle"
                    active={ROUTES.DASHBOARD === props.location.pathname}
                    to={ROUTES.DASHBOARD}
                    content="Dashboard"
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        )}
      </Grid>
      <Divider hidden />
    </Segment>
  );
};
export default React.memo(withRouter(withFirebase(Header)));
