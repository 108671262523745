import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { withAuthentication, AuthUserContext } from "../Session";
// Components
import Header from "../Header";
import Footer from "../Footer";
import RenderLoader from "./renderLoader"
// Pages
// import signup from "../../components/pages/signupResp";
import Signup from "../SignUp";
import Login from "../Login";
import Home from "../Home";
import privacy from "../../components/pages/privacy";
import Account from "../../components/pages/account";
import Dashboard from "../../componentsNew/Dashboard";
import PasswordForgetPage from "../PasswordForget";
import Test from "../../componentsNew/Test";
import Quiz from "../Quiz"

const Routes = () => {
  return (
    <Suspense fallback={<RenderLoader />}>
      <Router>
        <AuthUserContext.Consumer>
          {(authUser) => <Header authUser={authUser} />}
        </AuthUserContext.Consumer>
        <Switch>
          <Route path={ROUTES.HOME} exact component={Home} />
          <Route path={ROUTES.QUIZ} exact component={Quiz} />
          <Route path={ROUTES.DASHBOARD} exact>
            <AuthUserContext.Consumer>
              {(authUser) => <Dashboard authUser={authUser} />}
            </AuthUserContext.Consumer>
          </Route>
          <Route path={ROUTES.PRIVACY} exact component={privacy} />
          <Route path={ROUTES.ACCOUNT} exact component={Account} />
          <Route exact path={ROUTES.LOG_IN} component={Login} />
          <Route exact path={ROUTES.SIGN_UP} component={Signup} />
          <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route exact path="/test" component={Test} />
        </Switch>
        <Footer />
      </Router>
    </Suspense>
  );
};

export default React.memo(withAuthentication(Routes));
