import React from "react";
import { Divider, Grid, Segment, Header } from "semantic-ui-react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import ActionCardSlider from "./ActionCardsSlider";
import ActionCarouselDots from "./ActionCarouselDots";
import ActionCardScrollable from "./ActionCardSrollable";
import "pure-react-carousel/dist/react-carousel.es.css";
import "pure-react-carousel/dist/react-carousel.cjs.css";
import "../../app3.css";

// class Actions extends Component {
const Actions = (props) => {
  const { updateUser } = props;
  const [userActions, setUserActions] = React.useState(props.userActions);
  React.useEffect(() => {
    setUserActions(props.userActions);
  }, [props.userActions]);

  const [Commits, setCommits] = React.useState(props.Commits);
  React.useEffect(() => {
    setCommits(props.Commits);
  }, [props.Commits]);

  const [Completes, setCompletes] = React.useState(props.Completes);
  React.useEffect(() => {
    setCompletes(props.Completes);
  }, [props.Completes]);

  const loading =
    typeof userActions === "undefined" &&
    typeof Commits === "undefined" &&
    typeof Completes === "undefined";

  const handleUndoCommit = (e, data) => {
    const { index } = data;
    const newUserActions = userActions.concat(
      Commits.filter((item) => item.id === index)
    );
    const newCommits = Commits.filter((item) => item.id !== index);
    updateUser()
      .update({
        userActions: newUserActions,
        Commits: newCommits,
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const handleUndoComplete = (e, data) => {
    const { index } = data;
    console.log(index, Completes)
    const incomplete = Completes.filter((item) => item.id === index);
    console.log(incomplete)
    incomplete[0].completed = false;
    const newCompletes = Completes.filter((item) => item.id !== index);
    const newCommits = Commits.concat(incomplete);
    updateUser()
      .update({
        Completes: newCompletes,
        Commits: newCommits,
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const handleRemove = (e, data) => {
    const { index } = data;
    const newUserActions = userActions.filter((item) => item.id !== index);
    const newCommits = Commits.concat(
      userActions.filter((item) => item.id === index)
    );
    updateUser()
      .update({
        userActions: newUserActions,
        Commits: newCommits,
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const handleComplete = (e, data) => {
    const { index } = data;
    const newCommits = Commits.filter((item) => item.id !== index);
    const complete = Commits.filter((item) => item.id === index);
    complete[0].completed = true;
    const newCompletes = Completes.concat(complete);
    updateUser()
      .update({
        Commits: newCommits,
        Completes: newCompletes,
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  if (loading) {
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column
            width="7"
            textAlign="center"
            content={<Segment basic loading />}
          />
          <Grid.Column width="2" />
          <Grid.Column
            width="7"
            textAlign="center"
            content={<Segment basic loading />}
          />
        </Grid.Row>
      </Grid>
    );
  } else {
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width="7" textAlign="center" className="half-page">
            <Header as="h1" content="TAKE ACTION" />
            <Divider className="m1" />
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={props.width > 768 ? 100 : 60}
              orientation="horizontal"
              totalSlides={
                Object.keys(userActions.filter((c) => c.visible === true))
                  .length
              }
            >
              <Slider>
                {userActions
                  .filter((c) => c.visible === true)
                  .map((card, key) => {
                    return (
                      <Slide index={key} key={`action_${key}`}>
                        <div className="l-2 r-2 actions-carousel">
                          <ActionCardSlider
                            action
                            fluid
                            index={card.id}
                            handleRemove={handleRemove}
                            handleComplete={handleComplete}
                            pts={card.pts}
                            co2={card.co2}
                            name={card.name}
                          />
                        </div>
                      </Slide>
                    );
                  })}
              </Slider>
              <ActionCarouselDots
                slides={
                  Object.keys(userActions.filter((c) => c.visible === true))
                    .length
                }
              />
            </CarouselProvider>
          </Grid.Column>
          <Grid.Column width="2" />
          <Grid.Column width="7" textAlign="center" className="half-page">
            <Header as="h1" content="COMMITMENTS" />
            <Divider className="m1" />
            <div className="scrolling-right half-page-max">
              {Commits.map((card, i) => {
                return (
                  <ActionCardScrollable
                    index={card.id}
                    key={`commit_${i}`}
                    fluid
                    state={false}
                    handleRemove={handleRemove}
                    handleComplete={handleComplete}
                    handleUndo={handleUndoCommit}
                    handleIncomplete={handleUndoComplete}
                    pts={card.pts}
                    co2={card.co2}
                    name={card.name}
                    completed={card.completed}
                  />
                );
              })}

              {Completes.map((card, i) => {
                return (
                  <ActionCardScrollable
                    index={card.id}
                    fluid
                    key={`complete_${i}`}
                    state={false}
                    handleRemove={handleRemove}
                    handleComplete={handleComplete}
                    handleUndo={handleUndoCommit}
                    handleIncomplete={handleUndoComplete}
                    pts={card.pts}
                    co2={card.co2}
                    name={card.name}
                    completed={card.completed}
                  />
                );
              })}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

export default Actions;
