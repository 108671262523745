import React from "react";
// import SurveyData from "../../forms/SurveyData";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";
import {
  Header,
  Message,
  Form,
  Segment,
  Container,
  Grid,
  Input
} from "semantic-ui-react";

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const Signup = (props) => {
  const { firebase } = props;
  const [email, setEmail] = React.useState();
  const [passwordOne, setPasswordOne] = React.useState();
  const [passwordTwo, setPasswordTwo] = React.useState();
  const [handle, setHandle] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const SurveyData = JSON.parse(localStorage.getItem("badQuestionFix"))

    const Questions = {
      Q1: SurveyData[1],
      Q2: SurveyData[2],
      Q3: {
        Q31: SurveyData[3][1],
        Q32: SurveyData[3][2],
        Q33: SurveyData[3][3],
        Q34: SurveyData[3][4],
      },
      Q4: {
        Q41: SurveyData[4][1],
        Q42: SurveyData[4][2],
      },
      Q5: SurveyData[5],
      Q6: SurveyData[6],
      Q7: SurveyData[7],
      Q8: SurveyData[8],
      Q9: SurveyData[9],
      Q10: SurveyData[10],
      Q12:{
        Q121:{
          EF:0.332617,
          distance:0
        },
        Q122:{
          Bus:{
            EF:0.104220779,
            distance:0
          },
          Metro:{
            EF:0.0447,
            distance:0
          },
          Train:{
            EF:0.144020779,
            distance:0,
          },
        },
        Q123:{
          EF:0.255832,
          distance:0,
        }
      }
    };

    const userActions = [
      {
        id: "A1",
        name: "Replace conventional gasoline vehicle with hybrid car",
        pts: 220,
        co2: 0.9,
        //If value is greater than hybrid
        visible: SurveyData[1] > 0.25606 ? true : false,
        completed: false,
      },
      {
        id: "A2",
        name: "Replace conventional gasoline or hybrid vehicle with electric car",
        pts: 270,
        co2: 1.2,
        //If value is greater than scooter
        visible: SurveyData[1] > 0.10767 ? true : false,
        completed: false,
      },
      {
        id: "A3",
        name: "Fly 50% less",
        pts: 440,
        co2: 2.2,
        visible: true,
        completed: false,
      },
      {
        id: "A4",
        name: "Replace non-electric home energy appliances with electric appliances",
        pts: 240,
        co2: 1.0,
        //Any option other than none
        visible: SurveyData[6] > 0.0001 ? true : false,
        completed: false,
      },
      {
        id: "A5",
        name: "Compost all of your food waste",
        pts: 180,
        co2: 0.7,
        //Anything above 'all of it'
        visible: SurveyData[10] > 1.0 ? true : false,
        completed: false,
      },
      {
        id: "A6",
        name: "Change diet to vegetarien diet.",
        pts: 310,
        co2: 1.8,
        //Any diet with EF > vegetarian
        visible: SurveyData[9] > 1700 ? true : false,
        completed: false,
      },
      {
        id: "A7",
        name: "Take public transportation instead of taking Uber/Lyft/taxis",
        pts: 290,
        co2: 1.6,
        //hidden until the user inputs that they take this form of transit in the extended questions
        visible: false,
        completed: false,
      },
    ];

    firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        return firebase.user(authUser.user.uid).set({
          handle,
          email,
          Questions,
          Commits: [],
          Completes: [],
          userActions,
          userId: authUser.user.uid,
          createdAt:new Date().toISOString()
        });
      })
      // .then(() => {
      //   return this.props.firebase.doSendEmailVerification();
      // })
      .then(() => {
        localStorage.removeItem("badQuestionFix")
        props.history.push(ROUTES.DASHBOARD);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error)
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          setErrorMessage(ERROR_MSG_ACCOUNT_EXISTS);
        } else {
          setErrorMessage("We were unable to create your account at this time");
        }
        setError(true);
        setLoading(false);
      });
  };

  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === "" ||
    email === "" ||
    handle === "";

    const emailValid = RegExp(/^\S+@\S+\.\S+$/).test(email)
    console.log(emailValid)

  return (
    <Container fluid>
      <Segment basic fluid="true">
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h1" textAlign="center" content="Create Account" />
            <Segment stacked loading={loading}>
              <Form size="large" error={error} onSubmit={handleSubmit}>
                <Message error content={errorMessage} />
                <Form.Field
                  fluid
                  icon="user"
                  as={Input}
                  iconPosition="left"
                  name="Handle"
                  value={handle}
                  placeholder="Username"
                  onChange={(e, { value }) => setHandle(value)}
                />
                <Form.Field
                  fluid
                  icon="mail"
                  as={Input}
                  iconPosition="left"
                  name="email"
                  value={email}
                  placeholder="E-mail address"
                  onChange={(e, { value }) => setEmail(value)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  name="passwordOne"
                  value={passwordOne}
                  onChange={(e, { value }) => setPasswordOne(value)}
                  placeholder="Password"
                  type="password"
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={(e, { value }) => setPasswordTwo(value)}
                  placeholder="Confirm Password"
                  type="password"
                />
                <Form.Button
                  type="submit"
                  disabled={isInvalid}
                  style={{ backgroundColor: "#12d6b2" }}
                  fluid
                  size="large"
                  content="Create Account"
                />
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
};

export default withRouter(withFirebase(Signup));