import React from "react";
import { Accordion, Form } from "semantic-ui-react";
import ExtQs from "../ExtendedQuestions.json";
import RefConExp from "../referenceConsumption.json";
import PropTypes from "prop-types";

const Consumption = (props) => {
  const { Questions, updateUser } = props;
  const [loading, setLoading] = React.useState(null);

  const handleChange = (e, data) => {
    const { name, ef, value } = data;
    setLoading(name);
    updateUser()
      .update({
        [`Questions.Q11.${name}`]: {
          text: name,
          amount: Number(value),
          EF: Number(ef),
        },
      })
      .then(() => {
        setLoading(null);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const income = Questions.Q7;
  const Purchases = ExtQs.Consumption.Purchases.map((q, i) => {
    const { name, EF, question } = q;
    let placeholderVal = "$0";
    let value = "";

    if (Questions.Q11) {
      if (Questions.Q11[name]) {
        placeholderVal = `$ ${Questions.Q11[name].amount}`;
        value = Questions.Q11[name].amount;
      } else {
        if (income < 29999) {
          value = Number(`${RefConExp.first[name]}`);
        } else if (income > 29999 && income < 49999) {
          value = Number(`${RefConExp.second[name]}`);
        } else if (income > 49999 && income < 69999) {
          value = Number(`${RefConExp.third[name]}`);
        } else if (income > 69999 && income < 110000) {
          value = Number(`${RefConExp.fourth[name]}`);
        } else {
          value = Number(`${RefConExp.fifth[name]}`);
        }
      }
    } else {
      if (income < 29999) {
        value = `$ ${RefConExp.first[name]}`;
      } else if (income > 29999 && income < 49999) {
        value = `$ ${RefConExp.second[name]}`;
      } else if (income > 49999 && income < 69999) {
        value = `$ ${RefConExp.third[name]}`;
      } else if (income > 69999 && income < 110000) {
        value = `$ ${RefConExp.fourth[name]}`;
      } else {
        value = `$ ${RefConExp.fifth[name]}`;
      }
    }
    return (
      <Form.Input
        key={`consumptionField_${i}`}
        inline
        label={name}
        type="number"
        name={name}
        ef={EF}
        value={value}
        question={question}
        loading={name === loading}
        min={0}
        max={100000}
        step={1}
        onChange={handleChange}
        placeholder={placeholderVal}
      />
    );
  });

  const panels = [
    {
      key: "Consumption",
      title:
        "How much do you spend on the following goods and services in a year?",
      content: {
        content: <Form>{Purchases}</Form>,
      },
    },
  ];

  return <Accordion defaultActiveIndex={-1} panels={panels} />;
};

Consumption.propTypes = {
  updateUser: PropTypes.func.isRequired,
};

export default Consumption;
