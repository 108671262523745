import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import Q4AdultAdjusters from './Q4AdultAdjusters';
import Q4ChildAdjusters from './Q4ChildAdjusters';


export default class Q4 extends Component {

  render () {
    const state = this.props.state;
    return (
      <React.Fragment>
        <h2>How many people live in your house? </h2>
        <Form>
          <Form.Field>
          <Q4AdultAdjusters
            name="Adults (16 years and over)"
            state={state}
            step={state.step}
            min='0'
            max='8'
          />
          <Q4ChildAdjusters
            name="Children (under 16 years)"
            state={state}
            step={state.step}
            min='0'
            max='8'
          />
          </Form.Field>
        </Form>
      </React.Fragment>
    );
  }
}
