import React from "react";
import StepForm2 from "../../forms/StepForm_withProgress";
// import StepForm from "./StepForm";
import { Segment, Grid, Container, Header, Divider } from "semantic-ui-react";

const Quiz = (props) => {
  return (
    <React.Fragment>
      <Segment
        fluid="true"
        basic
        style={{ backgroundColor: "#12d6b2", marginTop: 0 }}
      >
        <Container fluid>
          <Grid columns="1">
            <Grid.Column
              textAlign="center"
              verticalAlign="middle"
              children={
                <Header
                  as="h1"
                  content="Discover Your Footprint"
                  subheader={
                    <Header
                      as="h2"
                      content="Give us some information to estimate your carbon footprint"
                      style={{ marginTop: "1em" }}
                    />
                  }
                />
              }
            />
          </Grid>
          <Divider hidden />
        </Container>
      </Segment>
      <Container text style={{ minHeight: "50vh" }}>
        <StepForm2 />
        {/* <StepForm /> */}
      </Container>
      <Divider hidden />
    </React.Fragment>
  );
};

export default Quiz;
