import React from "react";
import { Accordion, Radio, Input, Segment, Button } from "semantic-ui-react";
import ExtQs from "../ExtendedQuestions.json";
const questions = ExtQs.Transport;

// TODOs
// 1. figure out a better way to store the ExtQs - handling them all in JSON may become unmanageable;
// 2. debounce the input writing to firestore Directly

const Transport = (props) => {
  const { Questions, userActions, updateUser } = props;
  const [loading, setLoading] = React.useState("");

  const [WeeklyCommuteKms, setWeeklyCommuteKms] = React.useState(
    Questions.Q12.Q121.distance || null
  );

  const [rideShareKms, setRideShareKms] = React.useState(
    Questions.Q12.Q123.distance || null
  );

  const handleVehicleChange = (e, data) => {
    const { question, value } = data;
    setLoading("vehicle");
    updateUser()
      .update({
        [`Questions.${question}`]: Number(value),
      })
      .then(() => {
        setLoading("");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };
  const handleCommuteChange = (e, data) => {
    const { question, target, ef } = data;

    setLoading("commute");
    updateUser()
      .update({
        [`Questions.${question}.${target}`]: {
          distance: Number(WeeklyCommuteKms),
          EF: Number(ef),
        },
      })
      .then(() => {
        setLoading("");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };
  const handleRideshareChange = (e, data) => {
    setLoading("rideshare");
    const {
      makeUserActionCardVisible,
      question,
      target,
      userActionCard,
      ef,
      cardPresent,
    } = data;
    console.log(data);

    if (makeUserActionCardVisible && Number(rideShareKms) >= 0) {
      const newUserActions = userActions;
      newUserActions.filter((c) => c.id === userActionCard)[0].visible = true;
      updateUser()
        .update({
          userActions: newUserActions,
          [`Questions.${question}.${target}`]: {
            distance: Number(rideShareKms),
            EF: Number(ef),
          },
        })
        .then(() => {
          setLoading("");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } else if ((rideShareKms === "0" || rideShareKms === "") && cardPresent) {
      const newUserActions = userActions;
      newUserActions.filter((c) => c.id === userActionCard)[0].visible = false;
      updateUser()
        .update({
          userActions: newUserActions,
          [`Questions.${question}.${target}`]: {
            distance: Number(rideShareKms),
            EF: Number(ef),
          },
        })
        .then(() => {
          setLoading("");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } else if(rideShareKms !== null) {
      updateUser()
        .update({
          [`Questions.${question}.${target}`]: {
            distance: Number(rideShareKms),
            EF: Number(ef),
          },
        })
        .then(() => {
          setLoading("");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    }
  };

  const panels = [
    {
      key: "vehicle",
      title:
        "What best describes the vehicle you use for the majority of your travel by car?",
      content: {
        content: (
          <Segment basic loading={loading === "vehicle"}>
            {questions.Vehicle.map((option) => {
              const { name, EF, target } = option;
              return (
                <React.Fragment key={`option_${name}`}>
                  <Radio
                    label={option.name}
                    question={target}
                    value={EF}
                    checked={Questions.Q1 === EF}
                    onChange={handleVehicleChange}
                  />
                  <br />
                </React.Fragment>
              );
            })}
          </Segment>
        ),
      },
    },
    {
      key: "commute",
      title: "How many kms do you travel per week using your personal vehicle?",
      content: {
        content: (
          <Segment basic loading={loading === "commute"}>
            {questions.WeeklyCommute.map((q) => {
              const { question, target, name, EF } = q;
              return (
                <React.Fragment key={`option_${name}`}>
                  <Input
                    label={{ basic: true, content: name }}
                    style={{ marginBottom: "1em" }}
                    type="number"
                    name={name}
                    onChange={(e, { value }) =>
                      setWeeklyCommuteKms(Number(value))
                    }
                    min={0}
                    max={1000}
                    step={10}
                    value={WeeklyCommuteKms}
                    placeholder="0 km"
                  />
                  <Button
                    style={{ marginLeft: "1em" }}
                    basic
                    onClick={handleCommuteChange}
                    content="Update"
                    ef={EF}
                    question={question}
                    target={target}
                  />
                  <br />
                </React.Fragment>
              );
            })}
          </Segment>
        ),
      },
    },
    {
      key: "transit",
      title:
        "On average, how far do you commute using the following means of transport in a week? [km]",
      content: {
        content: (
          <Segment basic loading={loading === "transit"}>
            {questions.Commute.map((q) => {
              return (
                <TransitMethods
                  q={q}
                  Questions={Questions}
                  key={`option_${q.name}`}
                  setLoading={setLoading}
                  updateUser={updateUser}
                />
              );
            })}
          </Segment>
        ),
      },
    },
    {
      key: "rideshare",
      title:
        "Please estimate how many kilometers you travel using Taxi, Uber, or Lyft per week?",
      content: {
        content: (
          <Segment basic loading={loading === "rideshare"}>
            {questions.RideService.map((q) => {
              const { question, target, name, userActionCard, EF } = q;
              const cardPresent = userActions.filter(
                (c) => c.id === userActionCard
              );
              const makeUserActionCardVisible = Boolean(cardPresent.length)
                ? !cardPresent[0].visible
                : false;
              return (
                <React.Fragment key={`option_${name}`}>
                  <Input
                    label={{ basic: true, content: name }}
                    style={{ marginBottom: "1em" }}
                    type="number"
                    name={name}
                    onChange={(e, { value }) => setRideShareKms(Number(value))}
                    min={0}
                    max={1000}
                    step={1}
                    value={rideShareKms}
                    placeholder={rideShareKms + " km" || "0 km"}
                  />
                  <Button
                    style={{ marginLeft: "1em" }}
                    basic
                    onClick={handleRideshareChange}
                    content="Update"
                    makeUserActionCardVisible={makeUserActionCardVisible}
                    question={question}
                    target={target}
                    userActionCard={userActionCard}
                    ef={EF}
                    cardPresent={Boolean(cardPresent.length)}
                  />
                  <br />
                </React.Fragment>
              );
            })}
          </Segment>
        ),
      },
    },
  ];
  return <Accordion defaultActiveIndex={-1} panels={panels} />;
};

const TransitMethods = (props) => {
  const { Questions, q, setLoading, updateUser } = props;
  const { question, target, name, EF } = q;
  const [val, setVal] = React.useState(
    Questions.Q12.Q122[name].distance || null
  );
  const handleTransitChange = (e, data) => {
    const { question, target, ef, name } = data;
    setLoading("transit");
    updateUser()
      .update({
        [`Questions.${question}.${target}.${name}`]: {
          distance: Number(val),
          EF: Number(ef),
        },
      })
      .then(() => {
        setLoading("");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };
  return (
    <React.Fragment>
      <Input
        label={{ basic: true, content: name }}
        style={{ marginBottom: "1em" }}
        type="number"
        name={name}
        multiple={true}
        onChange={(e, { value }) => setVal(Number(value))}
        min={0}
        max={1000}
        step={1}
        value={val}
        placeholder={val + " km" || "0 km"}
      />
      <Button
        style={{ marginLeft: "1em" }}
        basic
        onClick={handleTransitChange}
        content="Update"
        ef={EF}
        question={question}
        target={target}
      />
      <br />
    </React.Fragment>
  );
};

export default Transport;
