import React from "react";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Sticky,
  Segment,
  Grid,
  Icon,
  Statistic,
  Dimmer,
  Loader,
  Divider,
  Header,
  Container,
  Message,
  Form,
  TextArea,
  Ref,
} from "semantic-ui-react";
import "../../app3.css";

import PieChartRespLogin4 from "../../charts/PieChartRespLogin4";
import PieChartMini from "../../charts/PieChartMini";
import TwoCharts from "../../charts/TwoCharts";
import CustomTabMenu from "./TabMenu";

import Actions from "./Actions";

const calcTotal = (data) => {
  let total = 0;
  data.forEach((dataPt, index) => {
    total += dataPt.value;
  });
  return total;
};

const calculateFootprint = (SurveyData) => {
  if (Object.keys(SurveyData).length > 0) {
    const vehicle = SurveyData.Q1;
    const distance = SurveyData.Q2;
    const regional = SurveyData.Q3.Q31;
    const national = SurveyData.Q3.Q32;
    const europe = SurveyData.Q3.Q33;
    const international = SurveyData.Q3.Q34;
    const flights =
      regional * 1000 + national * 3000 + europe * 6000 + international * 11000;
    const adults =
      SurveyData.Q4.Q41 > 0 ? SurveyData.Q4.Q41 : SurveyData.Q4.Q42;
    // const children = SurveyData.Q4.Q42;
    const home =
      typeof SurveyData.Q5.energy === "number"
        ? Math.round(SurveyData.Q5.energy * SurveyData.Q5.EF)
        : parseFloat(SurveyData.Q5);
    const energy = () => {
      if (SurveyData.Q6 === 122.19983142000001) {
        return Math.round(SurveyData.Q6 / 2);
      } else if (SurveyData.Q6 === 129.25609567) {
        return Math.round(SurveyData.Q6 / 2);
      } else if (SurveyData.Q6 === 109.06223199) {
        return Math.round(SurveyData.Q6 / 2);
      } else if (SurveyData.Q6 === 180.25907954000002) {
        return Math.round(SurveyData.Q6 / 3);
      } else {
        return Math.round(SurveyData.Q6);
      }
    };

    const income = SurveyData.Q7;
    const meals = parseFloat(SurveyData.Q8 / 3);
    const waste = parseFloat(SurveyData.Q10);
    const diet = parseFloat(SurveyData.Q9 * meals * waste);
    const transport = () => {
      if (SurveyData.Q12) {
        let detailedDistanceArray = SurveyData.Q12;
        var detailedDistance = 0;
        Object.keys(detailedDistanceArray).forEach((entry, index) => {
          if (Object.keys(detailedDistanceArray[entry]).length >= 2) {
            let deepAry = detailedDistanceArray[entry];
            if (!detailedDistanceArray[entry].EF) {
              Object.keys(deepAry).forEach((deepEntry, deepIndex) => {
                let deepNewEF = Math.round(
                  deepAry[deepEntry].EF * (deepAry[deepEntry].distance * 52)
                );
                detailedDistance += deepNewEF;
              });
            } else {
              let newEF = Math.round(
                detailedDistanceArray[entry].EF *
                  (detailedDistanceArray[entry].distance * 52)
              );
              if (entry !== "Q121") {
                detailedDistance += newEF;
              }
            }
          } else {
            if (Object.keys(detailedDistanceArray[entry]).length < 2) {
              let key = Object.keys(detailedDistanceArray[entry]);
              let newEF = Math.round(
                detailedDistanceArray[entry][key].EF *
                  (detailedDistanceArray[entry][key].distance * 52)
              );
              detailedDistance += newEF;
            } else {
              let newEF = Math.round(
                detailedDistanceArray[entry].EF *
                  (detailedDistanceArray[entry].distance * 52)
              );
              if (entry !== "Q121") {
                detailedDistance += newEF;
              }
            }
          }
        });
        return (
          detailedDistance +
          Math.round(
            SurveyData.Q12.Q121
              ? SurveyData.Q12.Q121.distance * 52 * vehicle
              : distance * vehicle
          )
        );
      } else {
        return Math.round(distance * vehicle);
      }
    };

    const travel = () => {
      if (SurveyData.Q3.Q35) {
        let detailedFlights = SurveyData.Q3.Q35;
        let totalFlightEF = 0;
        detailedFlights.forEach((flight, index) => {
          if (flight.destination > 0) {
            totalFlightEF += flight.destination * flight.destinationEF;
          }
          if (flight.return > 0) {
            totalFlightEF += flight.return * flight.returnEF;
          }
        });
        return Math.round(totalFlightEF);
      } else {
        return Math.round(flights * 0.117366667);
      }
    };
    const fuel = () => {
      if (home === "459.3522") {
        return Math.round((home + energy() * 7.46666667) / adults);
      } else if (home === "430.2226") {
        return Math.round((home + energy() * 5.59999999999999) / adults);
      } else if (home === "232.8335") {
        return Math.round((home + energy() * 3.4) / adults);
      } else {
        return Math.round((home + energy() * 2.89999999999999) / adults);
      }
    };
    const community = SurveyData.Q13 ? Math.round(SurveyData.Q13) : 323;
    const consumption = () => {
      if (SurveyData.Q11) {
        let DetailedConsumptionArray = SurveyData.Q11;
        var DetailedConsumption = 0;
        Object.keys(DetailedConsumptionArray).forEach((entry, index) => {
          DetailedConsumption +=
            DetailedConsumptionArray[entry].amount *
            DetailedConsumptionArray[entry].EF;
        });
        return Math.round(DetailedConsumption / adults);
      } else {
        if (income < 29999) {
          return 1147.5 / adults;
        } else if (income > 29999 && income < 49999) {
          return Math.round(2095 / adults);
        } else if (income > 49999 && income < 69999) {
          return Math.round(2949 / adults);
        } else if (income > 69999 && income < 110000) {
          return Math.round(3615 / adults);
        } else {
          return Math.round(5452.5 / adults);
        }
      }
    };

    return [
      { key: "Consumption", value: consumption(), color: "#18BCD7" },
      { key: "Diet", value: diet, color: "#2384D1" },
      { key: "Community", value: community, color: "#EB6890" },
      { key: "Energy", value: fuel(), color: "#FE9331" },
      { key: "Travel", value: travel(), color: "#8DEACF" },
      { key: "Transport", value: transport(), color: "#20D8B4" },
    ];
  } else {
    return null;
  }
};

const genChartData = (Commits, Completes, total) => {
  const chartData = [];
  chartData.push({ name: "Signup", Emmissions: total, Points: 0 });

  var runSumPoints = 0;
  var runSumEmissions = total;
  if (Completes.length > 0) {
    Completes.forEach((entry, index) => {
      runSumPoints += entry.pts;
      runSumEmissions -= entry.co2*1000;
      chartData.push({
        name: `Completed ${index}`,
        Emmissions: runSumEmissions,
        Points: runSumPoints,
      });
    });
  }

  if (Commits.length > 0) {
    Commits.forEach((entry, index) => {
      runSumPoints += entry.pts;
      runSumEmissions -= entry.co2 *1000;
      chartData.push({
        name: "Committments",
        "Predicted Emmissions": runSumEmissions,
        "Predicted Points": runSumPoints,
      });
    });
  }
  return chartData;
};

const gentotalsData = (Commits, Completes) => {
  const totals = {
    totalPoints: 0,
    totalCommittedPoints: 0,
    totalReducedEmissions: 0,
    totalCommittedReduction: 0,
  };

  if (Completes.length > 0) {
    Completes.forEach((entry, index) => {
      totals.totalPoints += entry.pts;
      totals.totalReducedEmissions += entry.co2 *1000;
    });
  }

  if (Commits.length > 0) {
    Commits.forEach((entry, index) => {
      totals.totalCommittedPoints += entry.pts;
      totals.totalCommittedReduction += entry.co2 *1000;
    });
  }
  return totals;
};

const Dash = (props) => {
  const contextRef = React.createRef();
  const { authUser, firebase } = props;
  const [userData, setUserData] = React.useState({});

  const { userActions, Commits, Completes, Questions, handle } = userData;

  const [PieData, setPieData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [ChartData, setChartData] = React.useState({});
  const [totals, setTotals] = React.useState({});
  const [ready, setReady] = React.useState(false);
  const [migrated, setMigrated] = React.useState(true);

  const {
    totalCommittedPoints,
    totalCommittedReduction,
    totalReducedEmissions,
    totalPoints,
  } = totals;

  React.useEffect(() => {
    const listener = firebase.user(authUser.uid).onSnapshot((doc) => {
      const user = doc.data();
      if (!user.Questions || !user.Commits || !user.Completes) {
        setMigrated(false);
        setReady(true);
      } else {
        const genPieData = calculateFootprint(user.Questions);
        const total = calcTotal(genPieData);
        const newChart = genChartData(user.Commits, user.Completes, total);
        const totals = gentotalsData(user.Commits, user.Completes);
        setPieData(genPieData);
        setTotal(total);
        setChartData(newChart);
        setTotals(totals);
        setUserData(user);
        setReady(true);
      }
    });
    return () => {
      listener();
    };
  }, [authUser.uid, firebase]);

  const updateUser = () => firebase.updateUser(authUser.uid);

  if (!ready) {
    return (
      <Segment fluid="true" basic>
        <Grid
          stackable
          textAlign="center"
          style={{ height: "70vh" }}
          verticalAlign="middle"
        >
          <Grid.Row>
            <Dimmer active inverted>
              <Loader size="medium" content="Loading your Profile..." />
            </Dimmer>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  } else if (!migrated) {
    return (
      <Segment fluid="true" basic>
        <Grid
          stackable
          textAlign="center"
          style={{ height: "70vh" }}
          verticalAlign="middle"
        >
          <Grid.Column width="16">
            <Segment basic>
              <Message icon warning>
                <Icon name="info circle" />
                <Message.Content>
                  <Message.Header
                    content="Profile Outdated"
                    style={{ padding: 0 }}
                  />
                  It appears your profile is outdated - please{" "}
                  <Link to="mailto:info@pledgetolead.ca?subject=Outdated User Profile">
                    let us know
                  </Link>{" "}
                  so we can fix it!
                </Message.Content>
              </Message>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  } else {
    return (
      <Segment fluid="true" basic>
        <Divider hidden />
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width="16" textAlign="center">
              <Header
                as="h1"
                content={`Welcome to your dashboard ${handle}!`}
              />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8" textAlign="center">
              <Header as="h3" content="YOUR FOOTPRINT" />
              <PieChartRespLogin4
                className="graph"
                data={PieData}
                total={total}
              />
            </Grid.Column>
            <Grid.Column width="8" textAlign="center">
              <Segment fluid="true" basic>
                <Header as="h3" content="YOUR PROGRESS" />
                <div className="twoCharts">
                  <TwoCharts
                    total={total}
                    ChartData={ChartData}
                    totalPoints={totalPoints}
                    totalCommittedReduction={totalCommittedReduction}
                    totalReducedEmissions={totalReducedEmissions}
                    totalCommittedPoints={totalCommittedPoints}
                  />
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Ref innerRef={contextRef}>
          <Container fluid>
            <Sticky
              context={contextRef}
              pushing={true}
              offset={isMobile ? 20 : 10}
            >
              <Segment raised fluid="true">
                <Grid
                  verticalAlign="middle"
                  textAlign="center"
                  divided="vertically"
                  columns={isMobile ? 2 : 4}
                >
                  {!isMobile && (
                    <Grid.Column
                      children={
                        <PieChartMini
                          className="graph"
                          data={PieData}
                          total={total}
                        />
                      }
                    />
                  )}

                  <Grid.Column
                    children={
                      <Statistic
                        size="small"
                        color="black"
                        value={total}
                        label="Initial Footprint (kg)"
                      />
                    }
                  />

                  {!isMobile && (
                    <Grid.Column
                      children={
                        <Statistic size="small" color="teal">
                          <Statistic.Value>
                            <Icon name="arrow down" />
                            {Number(totalReducedEmissions).toFixed(0)}
                          </Statistic.Value>
                          <Statistic.Label content="Reduction of Footprint (kg)" />
                        </Statistic>
                      }
                    />
                  )}

                  <Grid.Column
                    children={
                      <Statistic size="small" color="yellow">
                        <Statistic.Value>
                          <Icon name="plus" />
                          {totalPoints}
                        </Statistic.Value>
                        <Statistic.Label content="Points" />
                      </Statistic>
                    }
                  />
                </Grid>
              </Segment>
            </Sticky>
            <Segment basic>
              <Divider hidden />
              <Actions
                userActions={userActions}
                Commits={Commits}
                Completes={Completes}
                updateUser={updateUser}
              />
              <Divider hidden />
              <FeedbackArea feedback={firebase.feedback} />
              <Divider hidden />
              <CustomTabMenu
                updateUser={updateUser}
                Questions={Questions}
                userActions={userActions}
              />
              <Divider hidden />
            </Segment>
          </Container>
        </Ref>
      </Segment>
    );
  }
};

Dash.propTypes = {
  authUser: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
};

const FeedbackArea = (props) => {
  const { feedback } = props;
  const [value, setValue] = React.useState("");
  const [success, setSucces] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const sendFeedback = () => {
    setLoading(true);
    feedback()
      .add({
        timeStamp: Date.now(),
        message: value,
      })
      .then(() => {
        setLoading(false);
        setSucces(true);
        setValue("");
        setTimeout(() => setSucces(false), 3000);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };
  return (
    <Segment raised loading={loading}>
      <Header
        as="h3"
        content="Leave us some Feedback!"
        subheader={`We're currently in beta with this site, so please let us know what you'd like to see on the site. What actions do you think you could implement that would decrease your carbon footprint?`}
      />
      {success && (
        <Message header="Thanks for the feedback!" positive icon="thumbs up" />
      )}
      {!success && (
        <Form onSubmit={sendFeedback}>
          <TextArea
            placeholder="Leave us some feedback..."
            onChange={(e, { value }) => setValue(value)}
            value={value}
          />
          <Form.Button
            type="submit"
            icon="paper plane"
            content="Send"
            style={{ marginTop: "1em" }}
          />
        </Form>
      )}
    </Segment>
  );
};

const condition = (authUser) => authUser !== null;

export default withAuthorization(condition)(withFirebase(Dash));
