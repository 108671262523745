import React, { Component } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Segment } from 'semantic-ui-react'


class PieChartMini extends Component {

    state = {
        activeIndex: 0,
      };

	render () {

    // const renderActiveShape = (props) => {
    // const RADIAN = Math.PI / 180;
    // const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    //     fill } = props;
    // const sin = Math.sin(-RADIAN * midAngle);
    // const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    // const mx = cx + (outerRadius + 30) * cos;
    // const my = cy + (outerRadius + 30) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 12;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';

    // return (
    //     <g>
    //     <Sector
    //         cx={cx}
    //         cy={cy}
    //         innerRadius={innerRadius}
    //         outerRadius={outerRadius}
    //         startAngle={startAngle}
    //         endAngle={endAngle}
    //         fill={fill}
    //     />
    //     <Sector
    //         cx={cx}
    //         cy={cy}
    //         startAngle={startAngle}
    //         endAngle={endAngle}
    //         innerRadius={outerRadius + 6}
    //         outerRadius={outerRadius + 10}
    //         fill={fill}
    //     />
    //     <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
    //     </g>
    // );
    // };

    // const onPieEnter = (data, index) => {
    //     this.setState({
    //         activeIndex: index,
    //     });
    // }

    if (this.props.data === null) {
        return(
            <Segment basic loading className="full-h">
            </Segment>
        )
    } else {
        return (
            <React.Fragment>
                <div className="PieChartMini" >
                    <ResponsiveContainer width='100%' height='100%'>
                        <PieChart >
                            <Pie
                                nameKeyString="key"
                                data={this.props.data}
                                cx='50%'
                                cy='50%'
                                innerRadius='25%'
                                outerRadius="100%"
                                fill="#0500d6"
                                paddingAngle={0}
                            >
                                {this.props.data.map((entry, index) => <Cell key={`cell_${index}`} fill={entry.color}/>)}
                            </Pie>
                        </PieChart>
                </ResponsiveContainer>
            </div>
            </React.Fragment>
        );
    }
  }
};


export default PieChartMini;
