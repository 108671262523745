import React from 'react';
import { Form, Radio, Header} from 'semantic-ui-react';

const Q1 = props => {
  const {state, step, handleChange} = props
    return (
      <React.Fragment>
        <Header as="h2" content="What type of vehicle do you own?" />
        <Form>
          <Form.Field>
            <Radio
              label='Scooter or Motorcyle'
              name='vehicle'
              value={0.10767}
              checked={state[step] === 0.10767}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Conventional Gasoline'
              name='vehicle'
              value={0.28434}
              checked={state[step] === 0.28434}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Hybrid'
              name='vehicle'
              value={0.25606}
              checked={state[step] === 0.25606}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Electric'
              name='vehicle'
              value={0.0744}
              checked={state[step]  === 0.0744}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='None'
              name='vehicle'
              value={0.00001}
              checked={state[step] === 0.00001}
              onChange={handleChange}
            />
          </Form.Field>
        </Form>
      </React.Fragment>
    );
  }

export default Q1;

