import React from "react";
import {
  Placeholder,
  Segment,
  List,
  Container,
  Header,
  Divider,
} from "semantic-ui-react";
import { withFirebase } from "../Firebase";

const Test = (props) => {
  const { firebase } = props;
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    firebase
      .users()
      .get()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        setUsers(users);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, [firebase]);

  const ListPlaceholder = () => (
    <Placeholder>
      {[...Array(10)].map((x, i) => (
        <Placeholder.Header image key={i}>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      ))}
    </Placeholder>
  );
  
  return (
    <Container>
      <Segment basic textAlign="center" vertical>
        <Header as="h1" content="Users" />
      </Segment>
      <Segment padded="very" loading={!users.length > 0}>
        {/* <Button onClick={getUserData} content="Get Users" /> */}
        {/* <Button onClick={setUserData} content="Set Users" /> */}
        <List>
          {users.length > 0 &&
            users.map((u) => {
              return (
                <List.Item key={u.userId}>
                  <List.Icon name="user" />
                  <List.Content>
                    <List.Header>{u.handle}</List.Header>
                    <List.Description>
                      Joined: {new Date(u.createdAt).toLocaleDateString()}
                    </List.Description>
                    <List.List>
                      <List.Item>
                        <List.Icon name="mail" />
                        <List.Content>
                          <List.Header>{u.email}</List.Header>
                        </List.Content>
                      </List.Item>
                    </List.List>
                  </List.Content>
                </List.Item>
              );
            })}
          {!users.length > 0 && <ListPlaceholder />}
        </List>
      </Segment>
      <Divider hidden />
    </Container>
  );
};

export default withFirebase(Test);
