import React from "react";
import { Link } from "react-router-dom";
// import "../../App.css";
// import "../../app3.css";
import concordLogo from "../../constants/images/Concordia-Logo-Web-RGB.png";
import PeterGilganLogo from "../../constants/images/PeterGilganLogo.png";
import safLogo from "../../constants/images/saf_logo_white_text.png";
import { Divider, Grid, Header, Image } from "semantic-ui-react";

const Footer = () => {
  return (
    <footer className="footer p2ldark-blue quarter-page">
      <Grid stackable verticalAlign="middle" textAlign="center">
        <Grid.Row columns="equal">
          <Grid.Column>
            <Image
              size="medium"
              verticalAlign="middle"
              href="https://petergilganfoundation.org/"
              src={PeterGilganLogo}
              alt="Peter Gilgan Foundation"
              title="Peter Gilgan Foundation"
            />
          </Grid.Column>
          <Grid.Column>
            <Image
              size="medium"
              verticalAlign="middle"
              href="https://www.safconcordia.ca/"
              src={safLogo}
              alt="Sustainability Action Fund"
              title="Sustainability Action Fund"
            />
          </Grid.Column>
          <Grid.Column>
            <Image
              size="medium"
              verticalAlign="middle"
              href="https://www.concordia.ca/"
              src={concordLogo}
              alt="Concordia University"
              title="Concordia University"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns="equal">
          <Grid.Column>
            <Header
              as="h5"
              inverted
              textAlign="center"
              style={{ whitespace: "nowrap" }}
              content={
                <Link to="/pages/privacy" className="footerlinks">
                  PRIVACY POLICY
                </Link>
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Header
              as="h5"
              inverted
              textAlign="center"
              content={`© COPYRIGHT ${new Date().getFullYear()} PLEDGE TO LEAD`}
            />
          </Grid.Column>

          <Grid.Column>
            <Header
              as="h5"
              inverted
              textAlign="center"
              style={{ whitespace: "nowrap" }}
              content={
                <Link
                  to="http://www.concordia.ca/web/privacy.html"
                  className="footerlinks"
                >
                  TERMS AND CONDITIONS
                </Link>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </footer>
  );
};

export default Footer;
