import React from "react";
import { Accordion, Icon, Dropdown } from "semantic-ui-react";
import ExtQs from "../ExtendedQuestions.json";

const Community = (props) => {
  const [ActiveIndex, setActiveIndex] = React.useState(0);
  const [Loading, setLoading] = React.useState(false);
  const { Questions, updateUser } = props;

  const handleDropDownChange = (e, { value }) => {
    updateUser()
      .update({
        [`Questions.Q13`]: Number(value),
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const boroughs = ExtQs.Community.Borough.map((borough, key) => ({
    key: borough.EF.toString(),
    text: borough.Name,
    value: borough.EF,
  }));

  let placeHolderValue = "Borough";
  if (Questions.Q13) {
    placeHolderValue = boroughs.find((v) => v.value === Questions.Q13).text;
  }

  return (
    <Accordion>
      <Accordion.Title
        active={ActiveIndex === 1}
        index={1}
        onClick={() => setActiveIndex(1)}
      >
        <Icon name="dropdown" />
        Which borough do you live in?
      </Accordion.Title>
      <Accordion.Content active={ActiveIndex === 1}>
        <Dropdown
          loading={Loading}
          placeholder={[placeHolderValue]}
          search
          selection
          options={boroughs}
          onChange={handleDropDownChange}
        />
      </Accordion.Content>
    </Accordion>
  );
};

export default Community;
