import React from "react";
import {
  Form,
  Grid,
  Header,
  Container,
  Message,
  Segment,
} from "semantic-ui-react";
import * as ROUTES from "../../constants/routes";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { PasswordForgetLink } from "../PasswordForget";

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const LoginForm = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const isInvalid = password === "" || email === "";

  const handleSubmit = () => {
    const { firebase } = props;
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        props.history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          setErrorMessage(ERROR_MSG_ACCOUNT_EXISTS);
        } else {
          setErrorMessage("We were unable to log you in at this time");
        }
        setError(true);
      });
  };

  return (
    <Container fluid>
      <Segment basic fluid="true">
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
         
          <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h1" textAlign="center" content="Sign In" />
            <Segment stacked>
              <Form size="large" error={error} onSubmit={handleSubmit}>
                <Message error content={errorMessage} />
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  name="email"
                  value={email}
                  placeholder="E-mail address"
                  onChange={(e, { value }) => setEmail(value)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  name="password"
                  value={password}
                  onChange={(e, { value }) => setPassword(value)}
                  placeholder="Password"
                  type="password"
                  style={{ marginBottom: 0 }}
                />
                <PasswordForgetLink />
                <Form.Button
                  type="submit"
                  disabled={isInvalid}
                  style={{ backgroundColor: "#12d6b2" }}
                  fluid
                  size="large"
                  content="Sign In"
                />
              </Form>
            </Segment>
            <Message>
              Don't have an Account?{" "}
              <Link to={ROUTES.QUIZ}>Sign Up here</Link>
            </Message>
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
};

export default withRouter(withFirebase(LoginForm));
