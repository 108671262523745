import React, { Component } from 'react';

import Success from './Success';
import FormElement from './FormElement';
import SurveyData from './SurveyData';

export default class StepForm2 extends Component {

  state = {
    1: 0,
    2: 0,
    3: {
      1:0,
      2:0,
      3:0,
      4:0
    },
    4: {
      1:0,
      2:0
    },
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    step: 1,
    firstName: "",
    lastName: "",
    email: "",
    km: 0,
    flts: 0,
    ppl: 0,
    income: 0,
    meals: 0,
    waste: '',
    error:[]
  }

  skip = () => {
    const {step} = this.state;
      this.setState({
        step: step+1,
        error: []
      });
      SurveyData[this.state.step] = this.state[this.state.step]
  }

  nextStep = () => {
    const { step } = this.state;
    if (this.validateAnswer(this.state[this.state.step].length)) {
      this.setState({
        step: step+1,
        error: []
      });
    }
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step-1
    });
  }

  validateAnswer = ( value ) => {

    function sumAry(obj) {
      let sum = 0;
      for (let val  of Object.values(obj)) {
        sum += val;
      }
      return sum;
    }
    var ary = sumAry(this.state[this.state.step]);
    if ( !(this.state[this.state.step] > 0) && !ary > 0 ) {
      this.setState({
        error:`Error with value on Question ${this.state.step}`
      });
        return false;
      }
    SurveyData[this.state.step] = this.state[this.state.step];
    return true;
  }

  handleChangeP = (e, {value}) => {
    if (!value) {
      value = 0;
    }
    this.setState({
      [this.state.step]: value,
      error: []
    });

    SurveyData[this.state.step] = this.state[this.state.step];
  }

  render() {
    const { state, step, firstName, lastName, email, km, flts, ppl, home, energy, income, meals, waste } = this.state;
    const values = { firstName, lastName, email, km, flts, ppl, home, energy, income, meals, waste };

    switch(step) {
      case 1:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            handleChange = {this.handleChangeP}
          />
        );
      case 2:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
            idkFunc = {this.skip.bind(this)}
            idk
          />
        );
      case 3:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
            idkFunc = {this.skip.bind(this)}
            skip
          />
        );
      case 4:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
          />
        );
      case 5:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
          />
        );
      case 6:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
          />
        );
      case 7:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
          />
        );
      case 8:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
          />
        );
      case 9:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
          />
        );
      case 10:
        return (
          <FormElement
            step = {this.state.step}
            values = {values}
            state = {this.state}
            error = {this.state.error}
            nextStep={this.nextStep.bind(this)}
            prevStep = {this.prevStep.bind(this)}
            handleChange = {this.handleChangeP.bind(this)}
          />
        );
      case 11:
        return <Success values={this.state}/>;
      default:
        return state;
    }
  }
}
