import React from "react";
import { Container, Header, Icon, Segment } from "semantic-ui-react";


const RenderLoader = () => {
    return (
      <Container fluid textAlign="center">
        <Segment fluid="true" basic style={{ paddingTop: "20vh" }}>
          <Header as="h1" icon>
            <Icon name="sync" loading />
            Loading
            <Header.Subheader>
              Bringing up <strong>your</strong> customer Portal
            </Header.Subheader>
          </Header>
        </Segment>
      </Container>
    );
  };

  export default RenderLoader;