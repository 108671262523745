import * as React from "react";
import { Accordion, Icon, Button, Form, Input, Grid } from "semantic-ui-react";

const TravelAccordion = (props) => {
  const [ActiveIndex, setActiveIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { Questions, updateUser } = props;
  const [flightArray, setFlightArray] = React.useState(Questions.Q3.Q35 || []);
  const [canUpdate, setCanUpdate] = React.useState(true);

  const handleSubmit = (e, data) => {
    setLoading(true);
    const updateData = flightArray.filter(
      (f) => f.destination > 0 || f.return > 0
    );
    updateUser()
      .update({
        [`Questions.Q3.Q35`]: updateData,
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
    setCanUpdate(true);
  };
  const addFlight = (e, data) => {
    setCanUpdate(false);
    const newFlights = [
      ...flightArray,
      {
        flightnumber: `Trip${flightArray.length}`,
        index: Date.now(),
        destination: 0,
        return: 0,
      },
    ];
    setFlightArray(newFlights);
  };
  const updateFlight = (e, data) => {
    setCanUpdate(false);
    const SHef = 0.172996707,
      MHef = 0.098463227,
      LHef = 0.115038419;
    const SH = 463,
      MH = 1108;
    const { value, flighttype, index } = data;
    setFlightArray([
      ...flightArray.map((f) =>
        f.index === index
          ? {
              ...f,
              ...{
                [flighttype]: Number(value),
                [`${flighttype}EF`]:
                  value > MH ? LHef : value > SH && value < MH ? MHef : SHef,
              },
            }
          : { ...f }
      ),
    ]);
  };

  const deleteFlight = (e, data) => {
    setCanUpdate(false);
    const { index } = data;
    setFlightArray([...flightArray.filter((f) => f.index !== index)]);
  };
  return (
    <Accordion>
      <Accordion.Title
        active={ActiveIndex === 1}
        index={1}
        onClick={() => setActiveIndex(1)}
      >
        <Icon name="dropdown" />
        Estimate the distance of flights taken this year:
      </Accordion.Title>
      <Accordion.Content active={ActiveIndex === 1}>
        <Grid
          columns={1}
          divided
          className="t-1"
          style={{ marginBottom: "2em" }}
        >
          <Form loading={loading}>
            {flightArray.map((q, i) => {
              return (
                <FlightInput
                  q={q}
                  i={i}
                  updateFlight={updateFlight}
                  deleteFlight={deleteFlight}
                />
              );
            })}

            <Grid.Row>
              <Button
                size="small"
                type="submit"
                question="Q3"
                target="Q35"
                floated="left"
                content="Update"
                disabled={canUpdate}
                style={{ color: "white", backgroundColor: "#8DEACF" }}
                onClick={handleSubmit}
              />
              <Button
                basic
                size="small"
                floated="left"
                onClick={addFlight}
                question="Q3"
                target="Q35"
              >
                Add Flight
              </Button>
            </Grid.Row>
          </Form>
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};

const FlightInput = (props) => {
  const { q, i, updateFlight, deleteFlight } = props;
  const { destination, return: returnTrip, index, flightnumber } = q;
  return (
    <Grid.Row>
      <Form.Group inline>
        <Form.Field>
          <Input
            index={index}
            flighttype="destination"
            name={`Trip ${i + 1}`}
            label={{
              basic: true,
              content: `Trip ${i + 1} (Destination):`,
            }}
            placeholder="Destination (km)"
            onChange={updateFlight}
            type="number"
            value={destination > 0 ? destination : ""}
          />
        </Form.Field>
        <Form.Field>
          <Input
            index={index}
            flighttype="return"
            name={`Trip ${i + 1}`}
            label={{
              basic: true,
              content: `Trip ${i + 1} (Return):`,
            }}
            placeholder="Return (km)"
            onChange={updateFlight}
            type="number"
            value={returnTrip > 0 ? returnTrip : ""}
          />
        </Form.Field>
        <Button
          basic
          floated="right"
          content="Delete Flight"
          flightnumber={flightnumber}
          index={index}
          color="red"
          onClick={deleteFlight}
        />
      </Form.Group>
    </Grid.Row>
  );
};

export default TravelAccordion;
