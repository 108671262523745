import React from "react";
import { Accordion, Icon, Input, Button } from "semantic-ui-react";
import ExtQs from "../ExtendedQuestions.json";

const Energy = (props) => {
  const [ActiveIndex, setActiveIndex] = React.useState(0);
  const [Loading, setLoading] = React.useState(false);
  const { Questions, updateUser } = props;
  const EnergyQ = ExtQs.Energy;

  return (
    <Accordion>
      <Accordion.Title
        active={ActiveIndex === 1}
        index={1}
        onClick={() => setActiveIndex(1)}
      >
        <Icon name="dropdown" />
        Please estimate your annual electricity consumption:
      </Accordion.Title>
      <Accordion.Content active={ActiveIndex === 1}>
        {EnergyQ.Electricity.map((q, i) => {
          if (q.EF != null) {
            return (
              <EnergyQuestion
                q={q}
                Questions={Questions}
                setLoading={setLoading}
                updateUser={updateUser}
                Loading={Loading}
              />
            );
          } else {
            return false;
          }
        })}
      </Accordion.Content>
    </Accordion>
  );
};

const EnergyQuestion = (props) => {
  const { Loading, q, Questions, setLoading, updateUser } = props;
  const { question, Name, EF } = q;
  const [val, setVal] = React.useState(Questions[question].energy || null);
  const handleChange = (e, data) => {
    setLoading(true);
    const { ef, question } = data;
    updateUser()
      .update({
        [`Questions.${question}`]: {
          EF: ef,
          energy: Number(val),
        },
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  return (
    <React.Fragment>
      <Input
        loading={Loading}
        label={`${Name} [kWh]`}
        type="number"
        min={0}
        max={1000000}
        step={10}
        placeholder="kWh"
        value={val}
        onChange={(e, { value }) => setVal(Number(value))}
      />
      <Button
        style={{ marginLeft: "1em" }}
        basic
        onClick={handleChange}
        content="Update"
        ef={EF}
        question={question}
      />
    </React.Fragment>
  );
};

export default Energy;
