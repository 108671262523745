import React from "react";
import { Message, Grid, Progress, Button } from "semantic-ui-react";

import Q1 from "./Q1";
import Q2 from "./Q2";
import Q3 from "./Q3";
import Q4 from "./Q4";
import Q5 from "./Q5";
import Q6 from "./Q6";
import Q7 from "./Q7";
import Q8 from "./Q8";
import Q9 from "./Q9";
import Q10 from "./Q10";

const FormButtons = (props) => {
  const { baction, faction, idkFunc, idk, skip, step } = props;
  return (
    <React.Fragment>
      {step > 1 && <Button onClick={baction} content="Back" />}
      <Button onClick={faction} content="Continue" />
      {(idk || skip) && (
        <Button
          onClick={idkFunc}
          content={`${idk ? "I Don't Know/" : ""}None`}
        />
      )}
    </React.Fragment>
  );
};
const ProgressIndicator = ({ step }) => (
  <Progress percent={(step / 11) * 100} size="small" className="p2lgreen" />
);

const FormElement = (props) => {
  const { step, error } = props;

  const questions = {
    1: (
      <Q1
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    2: (
      <Q2
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    3: (
      <Q3
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    4: (
      <Q4
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    5: (
      <Q5
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    6: (
      <Q6
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    7: (
      <Q7
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    8: (
      <Q8
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    9: (
      <Q9
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
    10: (
      <Q10
        step={step}
        values={props.values}
        state={props.state}
        handleChange={props.handleChange}
      />
    ),
  };

  return (
    <Grid stackable>
      <Grid.Row columns="1">
        <Grid.Column children={<ProgressIndicator step={step} />} />
      </Grid.Row>
      <Grid.Row columns="1">
        <Grid.Column>
          {error.length > 0 && (
            <Message negative content="Don't Forget to Choose an Option!" />
          )}
          {questions[step]}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="1">
        <Grid.Column
          children={
            <FormButtons
              step={step}
              faction={props.nextStep}
              baction={props.prevStep}
              idkFunc={props.idkFunc}
              idk={props.idk}
              skip={props.skip}
            />
          }
        />
      </Grid.Row>
    </Grid>
  );
};

export default FormElement;
