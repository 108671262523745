import React from 'react';
import { Form, Radio } from 'semantic-ui-react';



const Q10= props => {
  return (
    <React.Fragment>
      <h2>How much of your food waste do you compost? </h2>
      <Form>
        <Form.Field>
          <Radio
            label='All of it'
            name='waste'
            value={1.0}
            checked={props.state[props.step] === 1.0}
            onChange={props.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Some but not all'
            name='waste'
            value={1.2}
            checked={props.state[props.step] === 1.2}
            onChange={props.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='None'
            name='waste'
            value={1.4}
            checked={props.state[props.step] === 1.4}
            onChange={props.handleChange}
          />
        </Form.Field>
      </Form>
  </React.Fragment>
  );
}

export default Q10;