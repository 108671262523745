import React, { Component} from 'react';
// import {isMobile} from "react-device-detect";
import '../../app3.css'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUserDetails } from '../../redux/actions/userActions';
import { Image, Form, Dimmer, Loader, Divider, Header, Message } from 'semantic-ui-react';
import moment from 'moment';
// import { getFormInitialValues } from 'redux-form';

class Account extends Component {
    constructor(props){
        super(props);
        this.state = {
            user:this.props.user,
            loaded:false,
            userdetails:'',
            email:'',
            handle:'',
            password:'',
            loading:false,
            error:false,
            success:false,
            errormsg:''
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if(Object.keys(prevState.user).length > 1 && prevState.loaded === false) {
            this.setState((prevState, props) =>{
                return({loaded:true,userdetails:props.user.credentials})
            });
        }

    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value });
    handleSubmit = () => {
        const { handle, email, password } = this.state;
        let profileChanges = {};
        if (password.length > 0){
            profileChanges = {...profileChanges, ...{"password":password}};
        }
        if (email.length > 0){
            profileChanges = {...profileChanges, ...{"email":email}};
        }
        if (handle.length > 0){
            profileChanges = {...profileChanges, ...{"handle":handle}};
        }

        console.log(profileChanges);
        this.setState({ submittedHandle: handle, submittedEmail: email, submittedPassword: password })
      }
    
    render() {
        const { handle, email, password, loading, error, success, errormsg } = this.state
        let loadingText = "";
        if (!this.state.loaded) {
            loadingText = "Loading your Profile...";
        } else {
            loadingText = 'Huh, not sure what Happened. Try reloading the page?';
        }

        if (!this.state.loaded) {
            return(
              <div className="ui content-container">
                <div className="ui stackable grid top2">
                  <div className="row two-thirds-page">
                      <Dimmer active inverted>
                        <Loader size='medium'>{loadingText}</Loader>
                      </Dimmer>
                  </div>
                </div>
              </div>
            )
          } else {
            return(
                <div className="ui content-container">
                     <div className="ui stackable grid top2">
                        <div className="row">
                            <div className="ui left aligned sixteen wide column">
                                <Header as ='h2'>My Account</Header>
                            </div>
                        </div>
                        <div className="row one-thirds-page">
                            <div className="ui centered four wide column">
                                <Image size="small" circular src={this.state.userdetails.imageUrl} />
                            </div>
                            <div className="ui left aligned eight wide column">
                                <Header as="h3">{this.state.userdetails.handle}</Header>
                                <Header as="h4">{this.state.userdetails.email}</Header>
                                <p>Member Since: {moment(this.state.userdetails.createdAt).format('MMMM Do YYYY')}</p>
                            </div>
                        </div>
                        <Divider horizontal>
                            <Header as="h4">
                                Edit My Account
                            </Header>
                        </Divider>
                        <div className="row"></div>
                        <div className="row two-thirds-page">
                            <div className="ui two wide column"></div>
                            <div className="ui left aligned twelve wide column">
                                <Form 
                                    onSubmit={this.handleSubmit} 
                                    loading={loading} 
                                    success={success} 
                                    error={error}
                                >
                                    <Message
                                        success
                                        content="Profile Updated!"
                                    />
                                    <Message
                                        error
                                        header="Whoops! Something went wrong..."
                                        content={errormsg}
                                    />
                                    <Form.Group widths='equal'>
                                        <Form.Input 
                                            fluid 
                                            label='Email' 
                                            name='email'
                                            value={email}
                                            placeholder={this.state.userdetails.email}
                                            onChange={this.handleChange} 
                                        />
                                        <Form.Input 
                                            fluid 
                                            label='Username' 
                                            name='handle'
                                            value={handle}
                                            placeholder={this.state.userdetails.handle}
                                            onChange={this.handleChange} 
                                        />
                                    </Form.Group>
                                    <Form.Input 
                                        type="password" 
                                        label='Password'
                                        name='password'
                                        value={password}
                                        placeholder='Enter a new Password' 
                                        width={8}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Button content='Update Profile' />
                                </Form>
                            </div>
                            <div className="ui two wide column"></div>
                        </div>
                    </div>
                </div>
            )
          }
        
    }
}
Account.propTypes = {
    user: PropTypes.object.isRequired,
    updateUserDetails: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapActionsToProps = {
    updateUserDetails,
};

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(Account);