import React, { Component } from 'react';
import { Input, Button, ButtonGroup } from 'semantic-ui-react';
import SurveyData from './SurveyData';

export default class Q8Adjusters extends Component {
  state = {[this.props.step]:this.props.state[this.props.step]};
  step = this.props.step;

  incrementValue = e => {
    const step = this.props.step;
    var curState = this.props.state;
    var SurveyStep = SurveyData[step];
    if (this.state[step] < this.props.max){
      curState[step] = curState[step] + 1;
      this.setState(curState);
      //SurveyStep = SurveyStep + 1;
      SurveyData[step] = SurveyStep;
    }
  }

  decrementValue = e => {
    const step = this.props.step;
    var curState = this.props.state;
    var SurveyStep = SurveyData[step];
    if (this.state[step] > this.props.min){
      curState[step] = curState[step] - 1;
      this.setState(curState);
      //SurveyStep = SurveyStep - 1;
      SurveyData[step] = SurveyStep;
    }
  }  

  render () {
    return (
      <React.Fragment>
      <div className="ui grid">
        <div className="ui left floated middle aligned ten wide column">
          <p>Number of Meals</p>
        </div>
        <div className="ui right floated middle aligned six wide column">
          <ButtonGroup basic>
            <Button icon="minus" className="button-theme" 
              labelPosition='right' 
              label={{as:'a', basic:true, content:`${this.state[this.step]}`, pointing:false, className:'numberlabel'}}
              onClick={this.decrementValue}/>
            <Button icon="plus"className="button-theme" onClick={this.incrementValue} />
          </ButtonGroup>
          <Input className="invisible" content={this.state[this.step]} />
        </div>
      </div> 
      </React.Fragment>
    )
  }
}
