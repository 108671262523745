import SurveyData from './SurveyData';
import React, { useState } from 'react';
import { Form, Label, Grid } from 'semantic-ui-react';
import { Slider } from "react-semantic-ui-range";

const Q7 = props => {
  const [value, setValue] = useState(0);
  let state = props.state[props.step];

  const settings = {
    start: state,
    min: 0,
    max: 200000,
    step: 10000,
    onChange: value => {
      props.state[props.step] = value;
      setValue(value);
      state = value;
      SurveyData[props.step] = value;
    }
  };

  return (
    <React.Fragment>
      <h2>What is your household income? </h2>
      <Form>
      <Form.Field>
        <Grid.Column width={16}>
            <Slider
              color="grey"
              inverted={false}
              style={{
                height:"2.4em",
                track: { top: "1.2em" },
                trackFill: { top: "1.2em" },
                thumb: {
                  backgroundColor: "grey",
                  width: "2.4em",
                  height: "2.4em"
                }
              }}
              settings={settings}
            />
          <Label color="grey">${value}/yr</Label>
      </Grid.Column>
      </Form.Field>
      </Form>
    </React.Fragment>
  );
}
export default Q7