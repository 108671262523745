import React from "react";
import { Label, Card, Button, Popup, Header } from "semantic-ui-react";

const ActionCardSlider = (props) => {
  const {
    index,
    handleRemove,
    handleComplete,
    handleUndo,
    action,
    name,
    pts,
    co2,
    fluid,
  } = props;
  const actionButton = action ? (
    <Button
      color="teal"
      index={index}
      onClick={handleRemove}
      content="Commit"
    />
  ) : (
    <Button.Group>
      <Button
        color="teal"
        index={index}
        onClick={handleComplete}
        content="Complete"
      />
      <Button color="red" index={index} onClick={handleUndo} content="Undo" />
    </Button.Group>
  );

  return (
    <Card color={action ? "yellow" : "green"} fluid={fluid} raised>
      <Card.Content>
        <Card.Header textAlign="left" content="GO DEEPER" />
        <Card.Meta style={{float:"right"}}>
            <Label
              size="small"
              color="yellow"
              content={pts}
              icon="plus"
            />
            <Label
              size="small"
              color="teal"
              content={co2 + " Tonnes"}
              icon="arrow down"
            />
            <Popup
              content="Tooltip/deeper explanation"
              position="top right"
              size="tiny"
              className="tooltip"
              trigger={<Button className="tiny" icon="question" />}
            />
        </Card.Meta>

        <Card.Description textAlign="center">
          <Header as="h3" content={name} />
          {actionButton}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
export default ActionCardSlider;
