import React, { Component } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import "../../app3.css";
import {
  Button,
  Header,
  Grid,
  Card,
  Image,
  Item,
  Divider,
  Container,
  Segment,
} from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import * as ROUTES from "../../constants/routes";
import rachel from "../../images/rachel.png"
import lindsay from "../../images/lindsay.png"
import matthew from "../../images/matthew.png"

// import CommentDetail2 from "../CommentDetail2";
import CommentCard from "./CommentCard";
import Faker from "faker";
import achievements from "../../images/achievements.png";
import actions from "../../images/actions.png";
import target from "../../images/your_target.png";

const commentCards = [
  "Reduced carbon footprint by 40% this month",
  "Moved up 60 spots on the leaderboard this month!",
  "Invited 5 friends to join Pledge To Lead",
];

const avatars = [rachel, matthew, lindsay]

class pageOne extends Component {
  render() {
    return (
      <React.Fragment>
        <Segment
          fluid="true"
          basic
          style={{ backgroundColor: "#12d6b2", marginTop: 0 }}
        >
          <Container fluid >
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width="8" textAlign={isMobile?"center":"left"} style={{paddingLeft:"4em", paddingRight:"4em"}}>
                <Header
                  as="h1"
                  content="REDUCE YOUR PERSONAL CONTRIBUTION TO CLIMATE CHANGE"
                />
                <Header
                  as="h2"
                  content="Pledge to halve your carbon footprint"
                />
                <Button
                  as={Link}
                  to={ROUTES.QUIZ}
                  size="large"
                  style={{
                    backgroundColor: "#f4d942",
                    color: "#00005A",
                    paddingLeft: "2.5em",
                    paddingRight: "2.5em",
                    fontSize: "1.2em",
                    marginBottom: "30px",
                  }}
                  content="GET STARTED"
                />
              </Grid.Column>
              <Grid.Column width="8" floated="left" textAlign="center" style={{paddingLeft:"4em", paddingRight:"4em"}}>
                <Card.Group itemsPerRow="1">
                  {commentCards.map((content,i) => (
                    <CommentCard
                      avatar={avatars[i]}
                      author={`${Faker.name.firstName()} ${Faker.name.lastName()}`}
                      content={content}
                      timeAgo={`${Faker.datatype.number({
                        min: 2,
                        max: 10,
                      })} days`}
                    />
                  ))}
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Container>
          <Divider hidden />
          <Divider hidden />
        </Segment>
        <Container>
          <Divider hidden />
          <Grid style={{ paddingTop: "2em" }} stackable>
            <Grid.Row>
              <Grid.Column textAlign="center" width="8">
                <Image
                  src={actions}
                  className="angled"
                  centered
                  size="large"
                  alt="Actions"
                />
              </Grid.Column>
              <Grid.Column
                floated="left"
                verticalAlign="middle"
                width="8"
                textAlign={isMobile ? "center" : "left"}
              >
                <Item>
                  <Header
                    as="h3"
                    content="Make small commitments to form long-term habits."
                  />
                  <Header
                    as="h4"
                    content="Which goods, services and activities produce the most
                      carbon emissions? What actions have the greatest impact on
                      reducing your carbon footprint? Use #PledgeToLead to
                      compare the environmental effects of your consumption
                      habits so you can make informed on how to reduce your
                      impact."
                  />
                </Item>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row reversed="computer">
              <Grid.Column textAlign="center" width="8">
                <Image
                  src={target}
                  className="angled"
                  centered
                  size="large"
                  alt="Target"
                />
              </Grid.Column>
              <Grid.Column
                floated="left"
                verticalAlign="middle"
                textAlign={isMobile ? "center" : "right"}
                width="8"
              >
                <Item>
                  <Header
                    as="h3"
                    content="View your progress every step of the way."
                  />
                  <Header
                    as="h4"
                    content="See how your actions reduce your carbon footprint and lead
                      you towards your pledge."
                  />
                </Item>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center" width="8">
                <Image
                  src={achievements}
                  className="angled"
                  centered
                  size="large"
                  alt="Achievements"
                />
              </Grid.Column>
              <Grid.Column
                floated="left"
                verticalAlign="middle"
                width="8"
                textAlign={isMobile ? "center" : "left"}
              >
                <Item>
                  <Header
                    as="h3"
                    content="Improve your footprint and lead the way"
                  />
                  <Header
                    as="h4"
                    content="Action is not without reward – earn points and
                      achievements as you complete your actions. Compete against
                      your friends to earn more accolades."
                  />
                </Item>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
          <Divider hidden />
        </Container>
        <Segment
          fluid="true"
          basic
          style={{
            backgroundColor: "#54D4F5",
            paddingTop: "3em",
            paddingBottom: "1em",
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          <Container fluid>
            <Grid stackable>
              <Grid.Row textAlign="center">
                <Grid.Column width="16" centered>
                  <Item>
                    <Header as="h2" content="PLEDGE TO LEAD TODAY" />
                    <Header
                      as="h3"
                      content="If you think you’re too small to make a difference, try
                      sleeping next to a mosquito."
                    />
                    <Button
                      as={Link}
                      to={ROUTES.QUIZ}
                      size="large"
                      style={{
                        backgroundColor: "#f4d942",
                        color: "#00005A",
                        paddingLeft: "2.5em",
                        paddingRight: "2.5em",
                        fontSize: "1.2em",
                        marginBottom: "30px",
                      }}
                      content="GET STARTED"
                    />
                  </Item>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </React.Fragment>
    );
  }
}
export default pageOne;
