import React, { Component } from 'react';
import PieChartResp from '../charts/PieChartResp'
import { Link } from 'react-router-dom';
import TakeThePledgeButton from '../elements/TakeThePledgeButton';
import { isMobile } from 'react-device-detect';
// import { connect } from 'react-redux';

export default class Success extends Component {

  constructor(props){
    super(props);
    let passedQuestions = {
      1:this.props.values[1],
      2:this.props.values[2],
      3:this.props.values[3],
      4:this.props.values[4],
      5:this.props.values[5],
      6:this.props.values[6],
      7:this.props.values[7],
      8:this.props.values[8],
      9:this.props.values[9],
      10:this.props.values[10]
    }
    this.state = this.calculatePieChart(passedQuestions)
  }

  calculatePieChart = (passedQuestions) =>{
    //really bad fix - Should be storing all questions in localstate as the user progresses
    localStorage.setItem("badQuestionFix", JSON.stringify(passedQuestions))

    let Questions = passedQuestions;
    let total;

    this.vehicle = Questions[1];
    this.distance = Questions[2];
    this.regional = Questions[3][1];
    this.national = Questions[3][2];
    this.europe = Questions[3][3];
    this.international = Questions[3][4];
    this.flights = (this.regional * 1000) + (this.national * 3000) + (this.europe * 6000) + (this.international * 11000);
    this.adults = Questions[4][1] > 0 ? Questions[4][1] : Questions[4][2];
    this.children = Questions[4][2];
    this.home = parseFloat(Questions[5]);
    this.energy = () => {
      if (Questions[6] === 122.19983142000001) {
        return (Math.round(Questions[6] / 2 ));
      } else if (Questions[6] === 129.25609567) {
        return (Math.round(Questions[6] / 2 ));
      } else if (Questions[6] === 109.06223199) {
        return (Math.round(Questions[6] / 2 ));
      } else if (Questions[6] === 180.25907954000002) {
        return (Math.round(Questions[6] / 3 ));
      } else {
        return (Math.round(Questions[6]));
      }
    };
    this.income = Questions[7];
    this.meals = Questions[8] / 3;
    this.waste = parseFloat(Questions[10]);
    this.diet = parseFloat(Questions[9] * this.meals * this.waste);
    this.transport = Math.round(this.distance * this.vehicle);
    this.travel = Math.round(this.flights * 0.117366667);
    this.fuel = () => {
      if (this.home === '459.3522') {
        return (Math.round(this.home) + Math.round(this.energy() *  7.46666667)) / this.adults;
      } else if (this.home === '430.2226') {
        return (Math.round(this.home) + Math.round(this.energy() * 5.59999999999999)) / this.adults;
      } else if (this.home === '232.8335') {
        return (Math.round(this.home) + Math.round(this.energy() * 3.4)) / this.adults;
      } else {
        return (Math.round(this.home) + Math.round(this.energy() * 2.89999999999999)) / this.adults;
      }
    };
    this.community = 323;

    this.consumption = () => {
      if (this.income < 29999) {
        return 1147.5 / this.adults;
      } else if (this.income > 29999 && this.income < 49999) {
        return Math.round(2095 / this.adults);
      } else if (this.income > 49999 && this.income < 69999) {
        return Math.round(2949 / this.adults);
      } else if (this.income > 69999 && this.income < 110000) {
        return Math.round(3615 / this.adults);
      } else {
        return Math.round(5452.5 / this.adults);
      }
    };

    total = this.diet + this.community + this.travel + this.fuel() + this.transport + this.consumption();

    this.data = [
      {key: 'Diet', value: this.diet, color:'#2384D1'},
      {key: 'Community', value: this.community, color:'#EB6890'},
      {key: 'Travel', value: this.travel, color:'#FE9331'},
      {key: 'Energy', value: this.fuel(), color:'#8DEACF'},
      {key: 'Transport', value: this.transport, color:'#20D8B4'},
      {key: 'Consumption', value: this.consumption(), color:'#18BCD7'}
    ];
    return ({
      PiechartData: this.data,
      total:total
    });
  }

  render () {
    console.log(this.state)
    
    let footprint = 'Your carbon footprint is higher than average. ';
    if (this.state.total <= 7000 && this.state.total > 5000) {
      footprint = 'Your footprint is close to average for your region. ';
    } else if (this.state.total <= 5000 ){
      footprint = 'Your footprint is below average! You are doing well, but can do even better. '
    }
    return (
      <div className="row three-quarter-page">
        <div className="ui center aligned eight wide column">
          <div className="pieOuter" style={{width:isMobile?"400px" :""}}>
            <PieChartResp className="graph" data={this.state.PiechartData} total={this.state.total}/>
            </div>
        </div>
        <div className="ui middle aligned eight wide column">
            <h3>{footprint} 
              Pledge to Lead to reduce your carbon emissions by 50% over the next 10 years</h3>
            <p>Create an account to input more data and get a more accurate score</p>
            <div className="ui center aligned">
            <Link to="/signup" className="item">
              <TakeThePledgeButton className="ui button" />
            </Link>
            </div>
        </div>
      </div>
    );
  }
}

