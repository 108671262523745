import React from "react";
import { Label, Card, Button, Icon, Dimmer, Header } from "semantic-ui-react";

const ActionCardScrollable = (props) => {
  const {
    completed,
    fluid,
    pts,
    co2,
    index,
    handleComplete,
    handleIncomplete,
    handleUndo,
    action,
    name,
  } = props;
  return (
    <Dimmer.Dimmable dimmed={completed} className="r-1 l-1 t-1" >
      <Card color={action ? "yellow" : "green"} fluid={fluid} raised>
        <Card.Content>
          <Card.Meta textAlign="right">
            <Label
              size="small"
              color="yellow"
              content={pts}
              icon="plus"
            />
            <Label
              size="small"
              color="teal"
              content={co2 + " Tonnes"}
              icon="arrow down"
            />
            <Button
              as={Icon}
              icon="undo"
              index={index}
              className="circular tiny"
              onClick={handleUndo}
            />
          </Card.Meta>
          <Card.Header textAlign="left">
            <Button
              size="tiny"
              disabled={completed}
              basic={!completed}
              color="teal"
              icon="checkmark"
              index={index}
              onClick={handleComplete}
              className={completed ? "" : "borderless"}
            />
            <span className="l-1 h3">{name}</span>
          </Card.Header>
        </Card.Content>
      </Card>
      <Dimmer active={completed} className="CompletedAction">
        <Header as="h2" content="Action Completed!" inverted />
        <Button
          icon="undo"
          index={index}
          className="circular inverted"
          onClick={handleIncomplete}
        />
      </Dimmer>
    </Dimmer.Dimmable>
  );
};
export default ActionCardScrollable;
