const SurveyData = {
    1: 0,
    2: 0,
    3: {
      1:0,
      2:0,
      3:0,
      4:0
    },
    4: {
      1:0,
      2:0
    },
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0
};

export default SurveyData;
