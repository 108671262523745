import React, { Component } from 'react';
import {Form } from 'semantic-ui-react';
import Q8Adjusters from './Q8Adjusters';


export default class Q8 extends Component {

  render () {
    const state = this.props.state;
    return (
      <React.Fragment>
        <h2>How many meals do you eat a day on average? </h2>
        <br />
        <Form>
          <Form.Field>
          <Q8Adjusters
            name="meals"
            state={state}
            step={state.step}
            min='1'
            max='5'
          />
          </Form.Field>
        </Form>
      </React.Fragment>
    );
  }
}