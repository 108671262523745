import React from "react";
import "../../App.css";
import { Grid, Header, Divider, Segment, Container } from "semantic-ui-react";

const Privacy = () => {
  return (
    <Segment fluid="true" basic style={{marginTop:0,marginBottom:0}}>
      <Grid stackable>
        <Grid.Column
          width="16"
          verticalAlign="middle"
          className="p2llight-blue"
        >
          <Divider hidden />
          <Segment
            basic
            fluid="true"
            padded="very"
            content={<Header as="h1" content="Web Privacy" />}
          />
          <Divider hidden />
        </Grid.Column>
        <Grid.Column width="16">
          <Container text>
            <p>
              Pledge To Lead ("PTL") is committed to respecting the privacy of
              any user or visitor to their websites ("You"). Any data or
              information that may be communicated to PTL by means of any of its
              websites are protected by the Quebec provincial Act Respecting
              Access to Documents Held by Public Bodies and the Protection of
              Personal Information (R.S.Q., chapter A-2.1), which requires PTL
              to maintain the confidentiality of all information and data they
              may collect, such as written, graphic, audio, visual digital,
              electronic, etc..
            </p>
            <p>
              What personal information does PTL collect and maintain? PTL
              usually collects information or data via our websites. Any
              information or data that may be collected that may allow for the
              identification of the user is kept and protected as strictly
              confidential information. This type of information is usually only
              obtained if provided by You via email, or other communication with
              PTL via the website. While no attempts are made to link individual
              users to information captured by PTL websites, the following data
              may be automatically collected: the IP address from which the site
              is accessed; the type of browser used to access the website, the
              date and time the site was accessed; the pages viewed and if
              applicable, the site that linked the user to the PTL website. This
              information would be used to improve the service offered by PTL
              websites. Any such information collected by PTL via its websites
              will not be transferred to any third parties in accordance with
              all applicable laws.
            </p>
            <p>
              PTL may use cookies to facilitate navigation on its websites.
              Cookies are special computer files automatically stored on the
              user's computer. From time to time, PTL may use cookies to monitor
              the areas of its websites accessed by users. Any user concerned
              about cookies may refer to the "help" information in their browser
              software for more information about cookies, how to disable them
              and how to delete them. The PTL website may contain links to other
              web sites. PTL is neither responsible for the content nor the
              privacy guidelines of these other sites. You should verify the
              privacy policies of sites linked to by PTL and to exercise your
              own good judgment regarding accuracy, and reliability of the
              information they contain.
            </p>
          </Container>
        </Grid.Column>
        <Divider hidden />
      </Grid>
    </Segment>
  );
};

export default Privacy;
