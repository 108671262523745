import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import Q3RegionalAdjusters from './Q3RegionalAdjusters';
import Q3NationalAdjusters from './Q3NationalAdjusters';
import Q3EuropeAdjusters from './Q3EuropeAdjusters';
import Q3InternationalAdjusters from './Q3InternationalAdjusters';;


export default class Q3 extends Component {

  render () {
    const state = this.props.state;
    return (
      <React.Fragment>
      <h2>How many return trips by plane have you made in the past year? </h2>
      <Form>
        <Form.Field>
        <Q3RegionalAdjusters
          name="Regional"
          state={state}
          step={state.step}
          min='0'
          max='8'
        />
        <Q3NationalAdjusters
          name="National or United States"
          state={state}
          step={state.step}
          min='0'
          max='8'
        />
        <Q3EuropeAdjusters
          name="Europe or South America"
          state={state}
          step={state.step}
          min='0'
          max='8'
        />
        <Q3InternationalAdjusters
          name="Other international"
          state={state}
          step={state.step}
          min='0'
          max='8'
        />
        </Form.Field>
      </Form>
      </React.Fragment>
    );
  }
}
