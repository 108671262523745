import PropTypes from "prop-types";
import { Dot, ButtonBack, ButtonNext } from "pure-react-carousel";
import React from "react";
import { Button, Container } from "semantic-ui-react";

const CustomDotGroup = ({ slides, size }) => (
  <Container textAlign="center" className="b-1 action-buttons">
    <ButtonBack className="ui basic button r-1">Back</ButtonBack>
    <Button.Group size={size}>
    {[...Array(slides).keys()].map(slide => (
        <Button as={Dot} key={slide} icon="circle" slide={slide} className="basic borderless" />
    ))}
    </Button.Group>
    <ButtonNext className="l-1 ui basic button ">Next</ButtonNext>
  </Container>
);

CustomDotGroup.defaultProps = {
  size: "mini"
};

CustomDotGroup.propTypes = {
  slides: PropTypes.number.isRequired,
  size: PropTypes.string
};

export default CustomDotGroup;
