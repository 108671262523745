export const HOME = '/';
export const SIGN_UP = '/signup';
export const LOG_IN = '/login';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/reset-password';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const DASHBOARD = "/dashboard"
export const QUIZ = "/quiz";
export const PRIVACY = "/privacy"
