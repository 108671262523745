import React, { Component } from 'react';
import { Scatter, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line, Area} from 'recharts';
import { Segment, Card } from 'semantic-ui-react';
import {isMobile} from "react-device-detect";


class TwoCharts extends Component {
    
    constructor(props){
      super(props);
      this.state = {
          bardata:[{
            name:'Progress',
            Points: this.props.totalPoints,
            "Commited Points": (this.props.totalPoints + this.props.totalCommittedPoints),
            Emmissions: this.props.total,
            "Completed Reduction": (this.props.total -this.props.totalReducedEmissions),
            "Committed Reduction":(this.props.total - this.props.totalCommittedReduction - this.props.totalReducedEmissions),
            "Target": Math.round(this.props.total/2)
          }],
          ChartData:this.props.ChartData
      }
    }

    componentDidUpdate(prevProps, prevState) {  
      // console.log('TwoChartsUpdated')
      // console.log(this.props.ChartData[0].Emmissions, this.state.ChartData[0].Emmissions)
      // console.log(JSON.stringify(this.state.ChartData) !== JSON.stringify(this.props.ChartData))

            if (JSON.stringify(this.state.ChartData) !== JSON.stringify(this.props.ChartData)) {
                // console.log('Update to ChartData')

                this.setState({
                    bardata: [{
                        name:'Progress',
                        Points: this.props.totalPoints,
                        "Commited Points": (this.props.totalPoints + this.props.totalCommittedPoints),
                        Emmissions: this.props.total,
                        "Completed Reduction": (this.props.total -this.props.totalReducedEmissions),
                        "Committed Reduction":(this.props.total - this.props.totalCommittedReduction - this.props.totalReducedEmissions),
                        "Target": Math.round(this.props.total/2)
                    }],
                    ChartData:this.props.ChartData,
                });
            }
        // }
    }  

    genTooltip(state, data){
      var theChartData;
      if (data.label === 'Progress') {
        theChartData = state.state.bardata
      } else {
        theChartData = state.state.ChartData;
      }

      function getCustomLabel(label) {
        let completed = new RegExp('completed','gi');
        if ( completed.test(label))  {
          return "Completed Actions";
        } else {
            return (label)
        }
      }

      function getCustomPoints(payload) {
        var Name = payload[0].payload.name;
        var dataPayload = theChartData.filter(x => x.name === Name);
        
          var newPayload = 0;
              dataPayload.forEach(value => {
        if (value.Points) {
            newPayload += value.Points
        } else if (value["Predicted Points"]) {
            newPayload += value["Predicted Points"]
        }
        })
        return newPayload;
      }
    
      function getCustomEmissions(payload) {
        var Name = payload[0].payload.name;
        var initialEmissions = theChartData[0].Emmissions;
        var dataPayload = theChartData.filter(x => x.name === Name);
        
        var newPayload = 0;
        let completed = new RegExp('completed','gi');
        dataPayload.forEach(value => {
          if (completed.test(value.name)){
            newPayload += (initialEmissions- value.Emmissions)
          } else if (value.Emmissions) {
              // newPayload += value.Emmissions
              newPayload += 0;
          } else if (value["Predicted Emmissions"]) {
              newPayload += (initialEmissions - value["Predicted Emmissions"])
          }
        })
        return (initialEmissions - newPayload.toFixed(0));
      }

      function getCustomEmissionsReduction(payload) {
        var Name = payload[0].payload.name;
        var initialEmissions = theChartData[0].Emmissions;
        var dataPayload = theChartData.filter(x => x.name === Name);
        console.log(payload, theChartData)
        var newPayload = 0;
        let completed = new RegExp('completed','gi');
        dataPayload.forEach(value => {
          if (completed.test(value.name)){
            newPayload += (initialEmissions- value.Emmissions)
          } else if (value.Emmissions) {
              // newPayload += value.Emmissions
              newPayload += 0
          } else if (value["Predicted Emmissions"]) {
              newPayload += (initialEmissions - value["Predicted Emmissions"])
          }
        })
        return (newPayload.toFixed(0));
      }

      if (data.active) {
        return (
          // <div className="custom-tooltip">
          //   <p className="label"> { getCustomLabel(label)  }</p>
          //   <p className="intro">Total Points: { getCustomPoints(payload) }</p>
          //   <p className="intro">Total Emissions Reduction: { getCustomPEmissions(payload) }</p>
          //   <p className="desc">Anything you want can be displayed here.</p>
          // </div>
          <Card className="tooltip-card">
            <Card.Content>
              <Card.Header content={ getCustomLabel(data.label)  } />
              {/* <Card.Meta content='date' /> */}
              <Card.Description textAlign="left">
                <div className="tooltip-points">Points: { getCustomPoints(data.payload) }</div>
                <div className="tooltip-emmissions">Emissions: { getCustomEmissions(data.payload)}</div>
                <div className="tooltip-emmissions-reduction">Reduction: { getCustomEmissionsReduction(data.payload)}</div>
                </Card.Description>
            </Card.Content>
          </Card>
        )
      }
      }

    render () {

        // console.log(this.state.ChartData)
        if (this.state.ChartData.length < 1) {
            return(
                <Segment basic loading className="full-h full-w">
                </Segment>
            )
        } else {
            return (
                <div className={isMobile?"mobile-twocharts":"desktop-twocharts"}>
                    <div className="AreaChart" >      
                        <ResponsiveContainer width="100%" height={400} syncId="progress">

                            <ComposedChart  data={this.state.ChartData} margin='auto' syncId="progress">
                              <XAxis dataKey="name" xAxisId="duplicated" allowDuplicatedCategory={false} hide/>
                              <XAxis dataKey="name" xAxisId="single" allowDuplicatedCategory={true} hide/>
                              <YAxis yAxisId="left" orientation="left" stroke="#000000"/>
                              <CartesianGrid strokeDasharray="3 3"/>
                              <Tooltip content={ this.genTooltip.bind(this.state.ChartData, this)}/>
                              {/* <Legend /> */}
                            
                              <Area type="monotone" xAxisId="duplicated"  yAxisId="left" dataKey="Points" stroke="#FBBD08" fill='#FF991D' />
                              <Line type="monotone" xAxisId="duplicated"  yAxisId="left" dataKey="Emmissions" stroke="#00B5AD" />
                              
                              <Line type="monotone" xAxisId="duplicated" yAxisId="left" dataKey="Predicted Emmissions" stroke="#00B5AD"/>
                              <Line type="monotone" xAxisId="duplicated" yAxisId="left" dataKey="Predicted Points" stroke="#FBBD08"/>
                              </ComposedChart>


                        </ResponsiveContainer>
                    </div>
                    <div className="BarChart">
                        <ResponsiveContainer width="100%" height={400} syncId="progress">
                            {/* <BarChart data={this.state.bardata} margin={'auto'} > */}
                            <ComposedChart data={this.state.bardata} margin={'auto'} >
                              <CartesianGrid strokeDasharray="3 3"/>
                              <XAxis xAxisId={0} dataKey="name" hide/>
                              <XAxis xAxisId={1} dataKey="name" hide/>
                              <XAxis xAxisId={2} dataKey="name" hide/>
                              <XAxis xAxisId={3} dataKey="name" hide/>
                              <XAxis xAxisId={4} dataKey="name" hide/>
                              <XAxis xAxisId={5} dataKey="name" hide/>
                              
                              <YAxis hide={true} />
                              {/* <Tooltip allowEscapeViewBox={{ x: true, y:false }} content={ this.genTooltip.bind(this.state.bardata, this)} /> */}
                              {/* <Legend /> */}
                              {/* <Tooltip /> */}
                              
                              <Bar dataKey="Emmissions" xAxisId={1}  stroke='#00B5AD' fill='#00B5AD' fillOpacity="0.1" />
                              <Bar dataKey="Committed Reduction" xAxisId={2}  stroke='#00B5AD' fill='#00B5AD' fillOpacity="0.15" />
                              <Bar dataKey="Completed Reduction" xAxisId={3}  stroke='#00B5AD' fill='#00B5AD' fillOpacity="0.2" />
                              <Bar dataKey="Commited Points" xAxisId={4} stroke='#FBBD08' fill='#FFC277' fillOpacity="0.7" />
                              <Bar dataKey="Points" xAxisId={0} stroke='#FBBD08' fill='#FFC277' fillOpacity="1" />
                              <Scatter type="monotone" dataKey="Target" xAxisId={5} shape="star" stroke='#00B5AD' fill='#00B5AD'  />
                            </ComposedChart> 
                            {/* </BarChart> */}
                        </ResponsiveContainer>
                    </div>
                </div>
                );
            }
        }
} 


export default TwoCharts;
