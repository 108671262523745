import React from "react";
import { Grid, Button, Transition, Segment } from "semantic-ui-react";

import Diet from "../ExtendedQuestions/Diet";
import Consumption from "../ExtendedQuestions/Consumption";
import Transport from "../ExtendedQuestions/Transport";
import Travel from "../ExtendedQuestions/Travel";
import Energy from "../ExtendedQuestions/Energy";
import Community from "../ExtendedQuestions/Community";
import { isMobile } from "react-device-detect";

const CustomTabMenu = (props) => {
  const { Questions, userActions, updateUser } = props;
  const [activeIndex, setActiveIndex] = React.useState(-1);

  const changeIndex = (e, { index }) => {
    if (index === activeIndex) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };
  const hide = 0,
    show = 500;
  const buttonStyle = {
    fontWeight: 400,
  };

  const buttonStyleMobile = isMobile
    ? { paddingLeft: "0.5em", paddingRight: "0.5em" }
    : {};

  return (
    <Segment
      basic
      fluid="true"
      style={
        isMobile
          ? {
              paddingLeft: 0,
              paddingRight: 0,
            }
          : {}
      }
    >
      <Grid stackable>
        <Grid.Row columns="equal">
          <Button.Group fluid widths="5">
            <Button
              size={isMobile ? "small" : "massive"}
              onClick={changeIndex}
              index={0}
              content="Diet"
              inverted
              style={{
                backgroundColor: "#2384D1",
                ...buttonStyle,
                ...buttonStyleMobile,
              }}
            />
            <Button
              size={isMobile ? "small" : "massive"}
              onClick={changeIndex}
              index={1}
              content="Consumption"
              inverted
              style={{
                backgroundColor: "#18BCD7",
                ...buttonStyle,
                ...buttonStyleMobile,
              }}
            />
            <Button
              size={isMobile ? "small" : "massive"}
              onClick={changeIndex}
              index={2}
              content="Transport"
              inverted
              style={{
                backgroundColor: "#20D8B4",
                ...buttonStyle,
                ...buttonStyleMobile,
              }}
            />
          </Button.Group>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid.Column width="16">
            <Transition
              visible={activeIndex === 0}
              unmountOnHide={true}
              animation="fade"
              duration={{ hide, show }}
            >
              <Segment fluid="true">
                <Diet
                  updateUser={updateUser}
                  Questions={Questions}
                  userActions={userActions}
                />
              </Segment>
            </Transition>
            <Transition
              visible={activeIndex === 1}
              unmountOnHide={true}
              animation="fade"
              duration={{ hide, show }}
            >
              <Segment fluid="true">
                <Consumption
                  updateUser={updateUser}
                  Questions={Questions}
                />
              </Segment>
            </Transition>
            <Transition
              visible={activeIndex === 2}
              unmountOnHide={true}
              animation="fade"
              duration={{ hide, show }}
            >
              <Segment fluid="true">
                <Transport
                  updateUser={updateUser}
                  Questions={Questions}
                  userCards={userActions}
                  userActions={userActions}
                />
              </Segment>
            </Transition>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Button.Group fluid widths="5">
            <Button
              size={isMobile ? "small" : "massive"}
              onClick={changeIndex}
              index={3}
              content="Travel"
              inverted
              style={{
                backgroundColor: "#8DEACF",
                ...buttonStyle,
                ...buttonStyleMobile,
              }}
            />
            <Button
              size={isMobile ? "small" : "massive"}
              onClick={changeIndex}
              index={4}
              content="Energy"
              inverted
              style={{
                backgroundColor: "#FE9331",
                ...buttonStyle,
                ...buttonStyleMobile,
              }}
            />
            <Button
              size={isMobile ? "small" : "massive"}
              onClick={changeIndex}
              index={5}
              content="Community"
              inverted
              style={{
                backgroundColor: "#EB6890",
                ...buttonStyle,
                ...buttonStyleMobile,
              }}
            />
          </Button.Group>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid.Column width="16">
            <Transition
              visible={activeIndex === 3}
              unmountOnHide={true}
              animation="fade"
              duration={{ hide, show }}
            >
              <Segment fluid="true">
                <Travel
                  edit={updateUser}
                  Questions={Questions}
                  updateUser={updateUser}
                />
              </Segment>
            </Transition>
            <Transition
              visible={activeIndex === 4}
              unmountOnHide={true}
              animation="fade"
              duration={{ hide, show }}
            >
              <Segment fluid="true">
                <Energy
                  edit={updateUser}
                  updateUser={updateUser}
                  Questions={Questions}
                />
              </Segment>
            </Transition>
            <Transition
              visible={activeIndex === 5}
              unmountOnHide={true}
              animation="fade"
              duration={{ hide, show }}
            >
              <Segment fluid="true">
                <Community
                  edit={updateUser}
                  updateUser={updateUser}
                  Questions={Questions}
                />
              </Segment>
            </Transition>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default CustomTabMenu;
